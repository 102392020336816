﻿// This is needed to vertically center the content in IE11
.heroblock {
  display: flex;
  height: 100%;
  flex-direction: column;
  padding-right: 0;
  padding-left: 0;
  margin: 0;
}

.hero {
  $hero: &;
  position: relative;
  display: flex;
  flex-flow: column wrap;
  overflow: hidden;
  height: 50vh;
  max-height: 700px;
  color: #fff;
  font-size: 16px;

  @media screen and (min-width: $screen-lg) {
    height: 60vh;  
  }

  &__media {
      position: absolute;
      z-index: 10;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;

      video {
          top: 0;
          height: 100%;
      }

      &:after {
          position: absolute;
          z-index: 0;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          content: "";
          opacity: 1;
          pointer-events: none;

          @at-root #{$hero}--overlay-dark#{$hero} & {
              background-color: #000;
              opacity: .25;
          }

          @at-root #{$hero}--overlay-light#{$hero} & {
              background-color: #fff;
              opacity: .25;
          }

          @at-root #{$hero}--overlay-lighter#{$hero} & {
              background-color: #fff;
              opacity: .45;
          }

          @at-root #{$hero}--overlay-orange#{$hero} & {
              background-color: $color-3;
              opacity: .25;
          }
      }
  }

  .object-fit {
      position: relative;
      z-index: -1;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
  }

  &__content-container {
      position: relative;
      z-index: 30;
  }

  &__content {
      position: relative;
      z-index: 30;
      display: flex;
      flex-flow: column wrap;
      height: 100%;
      padding-top: 3em;
      padding-bottom: 3em;

      @media screen and (min-width: $screen-sm) {
        padding-top: 4em;
        padding-bottom: 6em;
      }

      &--top {
        justify-content: flex-start;
      }

      &--middle {
        justify-content: center;
      }

      &--bottom {
        justify-content: flex-end;
      }

      &__inner {
        padding: 0;
      }
  }

  &__heading {
    font-size: 30px;

    @media screen and (min-width: $screen-sm) {
      font-size: 46px;
    }
  }
  
  &__text {
    font-size: 18px;

    @media screen and (min-width: $screen-sm) {
      font-size: 30px;
    }

  }
}

.welcome-text {
    background: #fff;
   


    h1 {
        font-size: $font-size-h3;
    }

    @media screen and (max-width: $screen-xs) {
        margin: $grid-gutter-width/2 ($grid-gutter-width/2*(-1));
    }
}

/*.main-content, .right-content{
    @media screen and (min-width: 0px){
        padding:0;
    }
}*/

.login-form { //on page loginform
    background: #fff;
    padding: 1em 1em 0.5em 1em;
    margin-bottom: 1em;
}

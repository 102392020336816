.share-bar {
  margin-bottom: 20px;

  .article-share-info {
    @include clearfix();
    width: 100%;
    padding: 10px 0;
    line-height: 3.1rem;
    display: flex;

    .share-intro {
      display: flex;
     
      #share-intro-icon{
        margin-right: 10px;
        font-size: 24px;
        vertical-align: middle;
      }
      
      #share-intro-text{
        margin-right: 10px;
        vertical-align: middle;
      }
    }

    > * {
      @media screen and (min-width: $screen-sm) {
        margin: 0;
      }
    }

    p {
      @media screen and (min-width: $screen-sm) {
        float: left;
      }
    }

    ul {
      @extend .list-inline;
      display: flex;
      flex-wrap: wrap;

      @media screen and (min-width: $screen-sm) {
        margin-left: 10px;
      }
    }
  }
}

body {
  font-weight: 400; 
}

h1, .h1,
h2, .h2,
h3, .h3 {
  /*margin-top: 0;*/
  margin-bottom: ($line-height-computed / 2);
  
  small,
  .small {
    font-size: 75%;
  }
}
h4, .h4,
h5, .h5,
h6, .h6 {
  margin-top: 0;
  margin-bottom: ($line-height-computed / 2);

  small,
  .small {
    font-size: 75%;
  }
}

p {
  margin: 0 0 $line-height-computed;

  @media (max-width: $screen-xs) {
    font-size: ($font-size-base*0.9);
  }
 
}

.lead {
  margin-bottom: $line-height-computed;
  font-size: ($font-size-base * 1.05);
  font-weight: 300;
  line-height: 1.4;

  @media (min-width: $screen-sm-min) {
    font-size: ($font-size-base * 1.5);
  }
}

a {
  text-decoration: none;
  
  &:hover,
  &:focus {
    text-decoration: underline;
    outline:none;
  }
}

h1{
     @media screen and (max-width: $screen-xs-max) {
        font-size: $font-size-h1*.70;
    }
}

h2{
     @media screen and (max-width: $screen-xs-max) {
        font-size: $font-size-h2*.70;
    }
}

h3{
     @media screen and (max-width: $screen-xs-max) {
        font-size: $font-size-h3*.70;
    }
}

h4{
     @media screen and (max-width: $screen-xs-max) {
        font-size: $font-size-h4*.70;
    }
}

h5{
     @media screen and (max-width: $screen-xs-max) {
        font-size: $font-size-h5*.70;
    }
}

h6{
     @media screen and (max-width: $screen-xs-max) {
        font-size: $font-size-h6*.70;
    }
}
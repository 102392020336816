$colors-list: (
    $color-1,
    $color-2,
    $color-3,
    $color-4,
    $color-5,
    $color-6,
    $color-7,
    $color-8,
    $color-9,
    $color-10,
    $color-11,
    $color-12,
    $color-13,
    $color-14,
    $color-15,
    $color-yellow, // Solros
    $color-tegel,  // Tegel
    $color-skog, // Skog
    $color-gras, // Gräs
    $color-sommarang, // sommaräng
    $color-mossa, // Mossa
    $color-vind, // Vind
    $color-havsbris, // Havsbris
    $color-hav, // Hav
    $color-horisont, // Horisont
    $color-himmel, // Himmel
    $color-midnatt, // Midnatt
    $color-marin, // Marin
    $color-lagun, // Lagun
    $color-berg, // Berg
    $color-betong, // Betong
    $color-silver, // Silver
    $color-kalk, // Kalk
);

$text-colors-list: (
  $color-1-text,
  $color-2-text,
  $color-3-text,
  $color-4-text,
  $color-5-text,
  $color-6-text,
  $color-7-text,
  $color-8-text,
  $color-9-text,
  $color-10-text,
  $color-11-text,
  $color-12-text,
  $color-13-text,
  $color-14-text,
  $color-15-text,
  #fff,  // Solros
  #fff,  // Tegel
  #fff,  // Skog
  #fff,  // Gräs
  #fff,  // sommaräng
  #fff,  // Mossa
  #fff,  // Vind
  #fff,  // Havsbris
  #fff,  // Hav
  #fff,  // Horisont
  #fff,  // Himmel
  #fff,  // Midnatt
  #fff,  // Marin
  #fff,  // Lagun
  #fff,  // Berg
  #fff,  // Betong
  $color-gray,  // Silver
  $color-gray,  // Kalk
);

$text-themes-list: (
  $text-color,
#fff,
  $color-primary,
  $color-tegel,
  $color-gras,
  $color-horisont
);

$text-themes-list-hover: (
  $text-color,
  #fff,
  shade($color-primary, 10%),
  shade($color-tegel, 10%),
  shade($color-gras, 10%),
  shade($color-horisont, 10%)
);

@each $current-color in $colors-list {
  $i: index($colors-list, $current-color);

  .iconblock {
    .color-#{$i} {
      > a,
      > div {
        background-color: $current-color;
        color: #000;
        // display: block;

        .icon {
          color: nth($text-colors-list, $i);
        }

        .text {
          color: nth($text-colors-list, $i);
        }

        svg {
          fill: currentColor;
        }
      }

      > a {
        transition: background-color .1s ease;
        // &,
        // &:hover,
        // &:focus {
        //   &,
        //   & .icon {
        //     transition: all .1s ease;
        //   }
        // }

        &:hover,
        &:focus {
          background-color: shade($current-color, 15%);
        }
      }
    }
  }

  .teaserblock .block-content {

    &:not(.floating-image).color-#{$i} {
      a:not(.btn) {
        color: #000;
        background-color: $current-color;

        &, &:hover, &:focus {
          transition: all .2s ease-in-out;
        }

        &:hover,
        &:focus {
          background-color: shade($current-color, 15%);
        }
      }
    }

    &.floating-image.color-#{$i} {
      .img-holder {
        background-color: $current-color;
      }
    }
  }

  .buttonblock .block-content {
    &.btn-block.color-#{$i} {
      border-top: solid 5px $current-color;

      @media screen and (max-width: $screen-xs) {
        border-top: 0;
      }

      .btn {
        &:hover,
        &:focus {
          color: nth($text-colors-list, $i);
          background: $current-color;
        }
      }
    }
  }
}

@each $current-color in $text-themes-list {
  $i: index($text-themes-list, $current-color);

  .quicklinkblock,
  .imageblock, 
  .teaserblock {
    & > .block-content {
      &.text-color-#{$i} {
        a {
          color: $current-color;

          &:hover,
          &:focus {
            color: nth($text-themes-list-hover, $i);
          }
        }

        .text {
          color: $current-color;
        }
      }
    }
  }

  .slideshowblock .item {
    &.text-color-#{$i} {
        
      .carousel-caption {
        color: $current-color;
          
        @if $i > 1 {
          text-shadow: 1px 1px #000000;
        } 
        @else {
          text-shadow: none;
        }

        @media screen and (max-width: $screen-sm-max) {
          color: $text-color;
          background: #fff;
        }
      }
    }
  }
}

/* General rule for text-color-X */
@each $current-color in $text-themes-list {
  $i: index($text-themes-list, $current-color);

  .text-color-#{$i} {
    .text {
      color: $current-color;
    }
  }
}

/* General rule for text-color-X */
@each $current-color in $colors-list {
  $i: index($colors-list, $current-color);

  .bg-color-#{$i} {
      background-color: $current-color;
    }
}

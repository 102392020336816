@font-face {
  font-family: "vxa-icons";
  src:url("/Static/dist/fonts/vxa-icons.eot");
  src:url("/Static/dist/fonts/vxa-icons.eot?#iefix") format("embedded-opentype"),
    url("/Static/dist/fonts/vxa-icons.woff") format("woff"),
    url("/Static/dist/fonts/vxa-icons.ttf") format("truetype"),
    url("/Static/dist/fonts/vxa-icons.svg#vxa-icons") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "vxa-icons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="vxa-icon-"]:before,
[class*=" vxa-icon-"]:before {
  font-family: "vxa-icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.vxa-icon-bird:before {
  content: "\61";
}
.vxa-icon-cow2:before {
  content: "\70";
}
.vxa-icon-farm:before {
  content: "\63";
}
.vxa-icon-forest:before {
  content: "\64";
}
.vxa-icon-milk:before {
  content: "\65";
}
.vxa-icon-pig:before {
  content: "\66";
}
.vxa-icon-plant2:before {
  content: "\71";
}
.vxa-icon-cows:before {
  content: "\68";
}
.vxa-icon-drop:before {
  content: "\69";
}
.vxa-icon-growing2:before {
  content: "\6a";
}
.vxa-icon-milk-machine2:before {
  content: "\72";
}
.vxa-icon-growing:before {
  content: "\6f";
}
.vxa-icon-cow:before {
  content: "\62";
}
.vxa-icon-plant:before {
  content: "\67";
}
.vxa-icon-milk-machine:before {
  content: "\6b";
}

#CybotCookiebotDialog {
  #CybotCookiebotDialogHeader {
    display: none;
  }

  @media screen and (min-width: 1280px) {
    .CybotCookiebotDialogContentWrapper {
      max-width: 1170px !important;
    }

    .CybotCookiebotDialogBodyButton {
      width: 250px !important;
    }
  }

  @media screen and (min-width: 1510px) {
    .CybotCookiebotDialogBodyBottomWrapper,
    #CybotCookiebotDialogBodyEdgeMoreDetails {
      margin-top: 1em !important;
    }
  }
}

#CookiebotWidget {
  bottom: 0.5em !important;
  left: 0.5em !important;
  width: 40px !important;
  height: 40px !important;
  min-width: 0 !important;
  min-height: 0 !important;

  @media screen and (min-width:$screen-md) {
    bottom: 2em !important;
    left: 2em !important;
    width: 50px !important;
    height: 50px !important;
  }

  &.CookiebotWidget-open {
    max-width: calc(100vw - 20px) !important;
    max-height: 100vh !important;
    width: auto !important;
    height: auto !important;

    .CookiebotWidget-logo {
      display: none;
    }

    #CookiebotWidget-widgetContent,
    .CookiebotWidget-header {
      display: flex !important;
    }
  }

  #CookiebotWidget-widgetContent,
  .CookiebotWidget-header {
    display: none !important;
  }


  .CookiebotWidget-logo,
  .CookiebotWidget-logo svg {
    width: inherit !important;
    height: inherit !important;
  }
}

@mixin active-filter-button() {
  color: #fff;
  background: $color-3;
  border: solid 1px $color-3;
}

.search-query {

  &:focus {
    background: #fff;
  }
}


//Search listing
.search-filter-holder {
  clear: both;
  border: none;
  @include make-xs-column(12);

  .customdocumentsearchpage & {
    padding: 0;
  }

  #search-wrapper {
    color: $text-color;
    background-color: #fff;
    position: relative;
    padding: $block-padding;
    margin: 0 0 $grid-gutter-width;
    @include block-radius-mixin();

    .did-you-mean {
      margin: 10px 0 1px 3px;

      @media screen and (min-width:$screen-xs) {
        &__text {
          display: inline;
        }

        &__hits {
          display: inline;
        }
      }
    }

    .input-group-btn {
      .btn {
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          fill: $color-white;
          width: 28px;
          height: 28px;
          margin-right: 0.8rem;
        }
      }
    }
  }

  .form-group {
    margin-bottom: 0;
  }

  .search-filter {

    h2 {
      font-size: $font-size-h4;
      padding: 15px 0 0 0;
      margin-top: 0;
      clear: both;
    }

    &.main-filters {
      body.no-js & {
        @media screen and (min-width:$screen-sm) {
          padding-right: 0;
        }
      }
    }

    @media screen and (max-width:$screen-xs-max) {
      padding-top: 8px;
    }
  }

  .filter-dates {
    padding-top: 5px;
  }

  .refine {
    clear: both;
  }

  .tab-pane {
    margin: 0;
  }
}

.extra-filters {

  &.filters-collapsible {
    padding: 0 20px 20px;

    .close-modal {
      z-index: 333;
    }
  }
}

.search-result-container {
  body.no-js & {
    @media screen and (min-width:$screen-sm) {
      @include make-sm-column(4);
      position: absolute;
      left: auto;
      right: 0;
      top: 110px;
    }
  }
}
//search listing
.search-result {
  position: relative;

  article {
    margin-bottom: 22px;
    position: relative;

    @keyframes fade {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    animation-name: fade;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    animation-delay: 0;
    animation-play-state: running;


    &.has-additional-marking {
      header, p, footer {
        width: 70%;

        @media screen and (max-width:$screen-xs) {
          width: 100%;
        }
      }

      .contact-card-hit-with-img {
        header, p, footer {
          clear: none;
        }
      }

      .contact-card-hit {
        p {
          width: 100%;

          @media screen and (max-width:$screen-xs) {
            margin-bottom: 22px;
          }
        }
      }

      .additional-marking {
        color: $color-white;
        background: $color-4;
        border-radius: $btn-border-radius-base;
        padding: 9px 18px;

        @media screen and (min-width:($screen-xs)+1) {
          position: absolute;
          right: 3rem;
          display: inline-block;
          top: 50%;
          transform: translateY(-50%);
        }

        @media screen and (max-width:$screen-xs) {
          display: block;
          margin-top: 1rem;
          text-align: center;
        }
      }
    }

    a {
      background: #fff;
      padding: 20px;
      position: relative;
      color: $text-color;
      display: block;

      &:hover, &:focus {
        text-decoration: none;
        background: $hover-background-color;
        @include block-bg-hover-mixin();
      }

      header {

        h2 {
          margin-bottom: .5rem;
        }

        time {
          margin-bottom: .3rem;
          display: block;
        }
      }

      em {
        font-style: normal;
      }

      p {
        margin: 0 0 6px;

        em {
          font-weight: bold;
        }
      }

      img {
        float: left;
        max-width: 120px;
        margin-left: 1em;
      }

      .meta {
        font-size: $font-size-small;
        font-weight: normal;
        font-style: normal;
        color: $gray-light;

        #search-tab-pane-02 & {
          &:first-child {
            font-size: 14px;
            color: #4a4a4a;
          }

          font-size: $font-size-base;
          color: $text-color;
        }
      }

      .breadcrumb-link {
        font-size: $font-size-small;

        ol {
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }
    }

    a {

      &[href$=".pdf"],
      &[href*=".pdf?"],
      &[href$=".doc"],
      &[href$=".docx"],
      &[href*=".doc"],
      &[href*=".docx"],
      &[href$=".jpg"],
      &[href$=".gif"],
      &[href*=".png"] {
        header {
          h2 {
            &:before {
              @extend .fa;
              font-size: 3.5rem;
              color: $gray;
              padding-right: 0.5rem;
            }
          }
        }
      }


      &[href$=".pdf"],
      &[href*=".pdf?"] {
        header {
          h2 {
            &:before {
              content: $fa-var-file-pdf-o;
            }
          }
        }
      }

      &[href$=".doc"],
      &[href$=".docx"],
      &[href*=".doc"],
      &[href*=".docx"] {

        header {
          h2 {
            &:before {
              content: $fa-var-file-word-o;
            }
          }
        }
      }

      &[href$=".jpg"],
      &[href$=".gif"],
      &[href*=".png"] {

        header {
          h2 {
            &:before {
              content: $fa-var-file-image-o;
            }
          }
        }
      }
    }

    .contact-card-hit {
      background: #fff;
      padding: 20px;
      position: relative;
      color: $text-color;
      display: block;

      header {
        h2 {
          margin-bottom: .5rem;
        }
      }

      .contact-img-placeholder {
        position: static;
        margin-bottom: 1em;
      }

      img {
        float: left;
        max-width: 120px;
        width: 100%;
      }

      a:not(.additional-marking) {
        background: inherit;
        padding: 0;
        color: $link-color;
        display: inline-block;

        &:hover,
        &:focus {
          background: inherit;
        }
      }
    }
  }
}

.best-bets {

  .teaser-block {
    position: relative;

    &:before {
      @extend .fa;
      content: $fa-var-star;
      position: absolute;
      color: #fff;
      left: 1em;
      top: 1.2em;
    }

    a {
      padding: 3em 1em 1em 1em;
      display: block;
      color: #fff;
      text-decoration: none;
      background: $color-4;
      transition: background ease-in-out .08s;

      &:before {
        content: "Vi rekommenderar";
        padding-left: 1em;
        display: block;
        position: absolute;
        top: 0.8em;
        font-style: italic;
        font-size: 1.3em;
      }

      img {
        float: right;
        max-width: 150px;
        margin-left: 1em;
        border: solid 3px #fff;

        @media screen and (max-width: $screen-xs-max) {
          float: none;
          margin: 0 0 1em 0;
        }
      }

      &:hover,
      &:focus {
        background: shade($color-4, 10%);
      }
    }
  }
}

.autocomplete-suggestions {
  background: #fff;
  border: solid 1px $gray-lighter;
  overflow: auto;

  > div {
    color: $link-color;
    border-top: solid 1px #e5e5e5;
    padding: 8px 10px;

    &:first-child {
      border: none;
    }

    &.autocomplete-selected {
      background: #f2f2f2;
    }

    &.autocomple-group {
      background: blue;

      strong {
        display: block;
      }
    }

    &:hover, &:focus {
      cursor: pointer;
      color: $link-hover-color;
    }
  }
}

.search-filter .toggle-button {
  margin: 0 $grid-gutter-width/3 $grid-gutter-width/3 0;

  @media screen and (max-width: $screen-xs-max) {
    float: none;
    display: block;
    margin: 0 0 $grid-gutter-width/3 0;
  }

  @media screen and (min-width: $screen-sm) {
    float: left;
  }

  input {
    position: absolute;
    top: -99px;
    opacity: 0;
    z-index: -1;
    display: none;

    &:checked + label {
      @include active-filter-button();
    }

    &:not(:checked):focus + label {
      outline: 0;
    }

    &:not(:checked):focus + label {
      text-decoration: underline !important;
    }
  }
}



.btn-filter {
  @extend .btn;
  @extend .btn-default;
  position: relative;
  overflow: hidden;
  display: block;

  &:before {
  }

  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: $screen-xs-max) {
    text-align: left;
  }

  body.no-js & {
    display: block;
    padding: 5px 0;
    margin: 0;
    filter: none;
    color: $link-color;
    background: transparent;
    border: solid 1px transparent;

    &:hover,
    &:active,
    &:focus {
      color: $link-hover-color;
      filter: none;
      background: transparent;
    }

    &:focus {
      border: solid 1px red;
    }
  }
  // ---------- ACTIVE
  &.filter-active {
    @include active-filter-button();
  }
  // ---------- DISABLED
  &.filter-disabled {
    &,
    &:hover,
    &:active,
    &:focus {
      color: $gray-light !important;
      box-shadow: none;

      body.no-js & {
        border: none !important;
      }
    }
  }
}


.pagination {
  margin: 0;
}

.search-tabs {
  margin-bottom: 15px;

  @media screen and (max-width: $screen-xs) {
    display: flex;

    a {
      font-size: 12px;
    }

    .tab-btn {
      padding: 10px 8px;
    }
  }
}

.search-tabs > li > a {
  background: #fff;
}

.nav-tabs.search-tabs > li.active > a {
  color: white;
}

.all-filters {
  display: none;
}

.search__margin {
  margin-top: .5em;
}

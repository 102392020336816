.top-content-area {
    position: relative;
    z-index: 0;
}

.right-content {
    @media screen and (min-width: $screen-sm) {
        margin-top: 43px;
    }
}

.top-content-area-subsite {
    margin-bottom: 15px;
}

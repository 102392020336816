$footer-blocks-bg: $color-16 !default;
$footer-info-bg: $footer-bg !default;
$footer-info-color: #fff !default;

.main-footer {
  position: relative;
  background: $footer-bg;

  .editorialblock, .gdprblock {
    margin-top: 40px;
    margin-bottom: 40px;

    p {
      margin: 0;
    }
  }
}

.footer-divider {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;

  svg {
    position: relative;
    display: block;
    width: 100%;
    height: calc(25px + (120 — 25) * ((100vw — 320px) / (1600 — 320)));
    max-height: 120px;
    margin-bottom: 4em;
  }

  .footer-shape-fill {
    fill: $content-bg;
  }
}

.footer-blocks {
  position: relative;
  height: 100%;
  background-image: linear-gradient(180deg, $gradient-to 0%, $gradient-from 100%);

  .container {
    @media screen and (max-width:$screen-xs-max) {
      display: none;
    }
  }

  .linkcollectionblock {
    h2 {
      font-size: $font-size-base;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .link-list {
    a {
      color: $gradient-text;
      padding-left: 0 !important;
      padding-right: 0;

      &:before {
        display: none !important;
      }
    }
  }

  a {
    color: $gradient-text;
  }
}

.footer-info {
  padding: 0;
  background: $color-1;

  p, a {
    color: $color-white;
    font-size: 18px;
  }

  .css & {
    background: $color-white;

    p, a {
      color: $color-gray;
    }
  }

  li {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 34px;
    min-height: 35px;
  }

  .editorialblock, .gdprblock {
    .share-icons {
      margin: -8px 0 0 0;
      display: flex;

      a {
        color: $color-tegel;
        font-size: 40px;
        line-height: 40px;
        border: none;
        border-radius: 0;
        background-image: none;
        width: 40px;
        height: 40px;

        &.podcast,
        &.instagram {
          background-color: #800303;
          border-radius: 21%;
          width: 34px;
          height: 34px;
          display: flex;
          justify-content: center;
          align-items: center;

          &:before {
            font-size: 26px;
          }

          &:hover:before {
            color: white;
          }
        }

        &.youtube {
          width: 45px;
          margin: 3px;
        }

        &.podcast {
            margin-left: -3px;
          &::before {
            font-size: 24px;
            color: white;
          }
        }

        @media screen and (max-width:$screen-sm-max) {
          margin: 6px 2.5px 0 2.5px;
          font-size: 29px;
          line-height: 29px;
          width: 29px;
          height: 29px;

          @supports (-webkit-touch-callout: none) {
            /* CSS specific to iOS devices */
            margin: 7px 2.5px 0 2.5px;
          }

          &.instagram {
            width: 26px;
            height: 25px;
            margin: 6px 0 0 0;

            &:before {
              font-size: 21px;
            }
          }

          &.youtube {
            width: 35px;
          }
        }

        &:focus, &:hover {
          color: $color-tegel;
          border: none;
          background-image: none;
        }
      }
    }
  }
}

.footer-for-print {
  z-index: 10;
  margin-top: 40px;
  margin-bottom: 30px;
  padding: 0px;

  .editorialblock, .gdprblock {
    border-radius: 5px;
    color: #fff !important;
    background-color: #F08A00 !important;
    -webkit-print-color-adjust: exact;
  }

  .row {
    margin: 0px;
  }


  a {
    color: #fff !important;
  }

  a:hover, a:focus, a:active {
    color: #fff !important;
  }

  .block:nth-child(2), .block:nth-child(3), .block:nth-child(4), .block:nth-child(5) {
    display: none;
  }

  .block {
    margin-bottom: 0px;
    max-height: 60px;
    height: 60px;

    p {
      color: #fff !important;
      margin-top: 20px;
      margin-left: 15px;
    }
  }
}

﻿.tags {
    margin-top: .9rem;

    span {
        //background: $btn-default-bg;
        //border-radius: $btn-border-radius-base;

        display: inline-block;
        padding: 6px 12px;
        margin: 0 0.35rem 0.35rem 0;

        &:first-of-type {
            padding-left: 0;
        }

        &:not(.tag-heading)::before {
            display: inline-block;
            content: "#";
        }
    }
}

.event-side-info {
  background: #fff;
  padding: $grid-gutter-width/2;

  dd {
    padding-bottom: 10px;
  }
}

.event-main-info {
  header {
    h1 {
      margin-top: 0;
    }
  }
}

.btn-register, .btn-contact-advisor {
  @extend .btn-secondary;
  width: 100%;
  margin-bottom: 10px;
  white-space: normal;
}


.ways-of-contact {
  margin-bottom: 30px;
  margin-bottom: 15px;
}

.specific-location-text, .specific-time-text {
  margin: 0 0 5px;

  &__date {
    margin-right: 5px;
    display: none;
  }
}

.application-deadline {
  margin-top: 15px;
  display: none;
}

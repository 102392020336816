.landingpage {
    .lead {
        font-size: $font-size-base;
    }

    .search-filter-holder {
        #search-wrapper {
            background-color: transparent;
            border-bottom: solid 1px $border-color;

            .toggle-button {

                .btn-filter {
                    padding-left: 15px;

                    &:before {
                        display: none;
                    }
                }
            }
        }
    }

    .search-result-listing {
        background: #fff;
        border-top: solid 5px $color-1;
        padding: $grid-gutter-width/2;
    }

    .search-hit {
        header {
            h2 {
                font-size: $font-size-h3;
            }
        }
    }
}
@media screen and (max-width: $screen-sm) {
  .flexible-landing-page {

    .editorialblock, .gdprblock {
      display: inline-block;
    }
  }
}

﻿$iconblock-fontsize:            110px;
$iconblock-fontsize-xs:         $iconblock-fontsize*.3;
$iconblock-fontsize-sm:         $iconblock-fontsize*.65;
$iconblock-fontsize-md:         $iconblock-fontsize*.85;


.iconblock {
    .block-content {
        > a {
            display: flex;
            flex-flow: row;
            align-items: center;
            padding: .75em;
            min-height: 80px;

            @include block-radius-mixin();

            @media screen and (min-width: $screen-sm) {
                flex-flow: column;
                justify-content: center;
                height: 157px;
            }

            @media screen and (min-width: $screen-md) {
                height: 212px;
            }

            @media screen and (min-width: $screen-lg) {
                height: 262px;
            }
        }
    }
     
    h2 {
        margin: 0;
        @media screen and (max-width: $screen-lg) {
            font-size: $font-size-h2*0.9;
        }
        @media screen and (max-width: $screen-md) {
            font-size: $font-size-h3*0.9;
        }
        @media screen and (max-width: $screen-xs) {
            font-size: $font-size-h4;
        }
    }

    a {
        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    .icon {
        font-size: $iconblock-fontsize-xs;
        line-height: 1;
        width: 40px;

        @media screen and (min-width: $screen-sm) {
            font-size: $iconblock-fontsize-sm;
            width: $iconblock-fontsize-sm;
            height: $iconblock-fontsize-sm;
            margin-bottom: .75rem;
        }

        @media screen and (min-width: $screen-md) {
            font-size: $iconblock-fontsize-md;
            width: $iconblock-fontsize-md;
            height: $iconblock-fontsize-md;
        }

        @media screen and (min-width: $screen-lg) {
            font-size: $iconblock-fontsize;
            width: $iconblock-fontsize;
            height: $iconblock-fontsize;
        }

        svg {
          width: 100%;
          height: 100%;
        }
    }

    @media screen and (max-width: $screen-xs-max) {
        @include make-xs-column(12);
    }

    @media screen and (max-width: $screen-sm-max) {
        @include make-sm-column(6);
    }
}

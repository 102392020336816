﻿.quicklinkblock {
  z-index: 1;
  margin-top: 30px;  

  @media screen and (min-width: $screen-sm) {
    margin-top: -5%;  
  }

  @media screen and (min-width: $screen-md) {
    margin-top: -3.5%;  
  }

  @media screen and (min-width: $screen-lg) {
    margin-top: -3%;  
  }

  .block-content {
    display: block;
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .5em .75em;
    background-color: #fff;
    border-radius: .75em;
    transition: all .25s ease-in-out 0s;
    will-change: transform,box-shadow;
    
    &:hover,
    &:focus {
      transform: translate3d(0,-3px,0);
    }
  }

  &-icon {
    flex: 0 0 50px;
    margin-right: .5ex;
    width: 50px;
    height: 50px;
    fill: currentColor;
  }

  h2 {
    flex: 0 1 auto;
    font-size: 24px;
    margin: 0;

    @media screen and (min-width: $screen-sm) {
      font-size: 20px;
    }

    @media screen and (min-width: $screen-lg) {
      font-size: $font-size-h2;
    }
  }
}

.contact-list-email-button {
  width: 40%;
  text-align: center;
  vertical-align: middle;

  @media screen and (min-width: $screen-md) {
    width: 30%
  }
}

.contact-list-p {
  margin: 0 0 0 0;
}

.contact-list-phone {
  display: block;
  padding: 10.5px 0;
  margin: 0 0 11px;
  font-size: 15px;
  line-height: 1.42857;
  color: #333333;
  word-break: break-all;

  @media screen and (min-width: $screen-md) {
    padding: 10.5px;
  }
}

.contact-list-bold {
  font-weight: bold;
}

.no-padding-left {
  padding-left: 0;
}


img.contact-list-arrow {
  float: right;
  width: 30px;
  height: 30px;
}

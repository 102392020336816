.error-page {
    background: $content-bg;
    text-align: center;
    height: 100%;

    .logo {
        position: relative;
        left: 50%;
        top: auto;
        text-align: center;
        padding: $grid-gutter-width 0;
        transform: translateX(-50%);
        margin-bottom: 3em;
    }

    .error-message {
        h1 {
            color: $color-primary;

            &, & * {
                line-height: 1.4em;
            }

            @media screen and (max-width: $screen-xs-max) {
                font-size: $font-size-h1*.55;
            }

            strong {
                display: block;
                font-size: $font-size-h1 * 1.25;

                @media screen and (max-width: $screen-xs-max) {
                    font-size: $font-size-h1*.9;
                }
            }
        }

        p {
            &.error-lead {
                font-family: $headings-font-family;
                font-size: 20px;
                font-weight: 600;
                margin-bottom: $grid-gutter-width/4;

                @media screen and (min-width:$screen-sm) {
                    font-size: 28px;
                }
            }
        }

        .img-holder {
            margin-bottom: $grid-gutter-width/2;

            img {
                @include img-responsive();
            }
        }
    }

    .links {
        margin: $grid-gutter-width*1.5 auto;

        > div {
            display: table;
            table-layout: fixed;
            width: 100%;
        }

        a {
            display: table-cell;
            width: 50%;
            vertical-align: middle;
            position: relative;

            @media screen and (min-width: $screen-sm) {
                padding-left: 65px;
            }
        }

        .home:before,
        .search:before {
            @extend .fa;
            font-size: 50px;
            padding-right: $grid-gutter-width/3;

            @media screen and (max-width: $screen-xs-max) {
                display: block;
            }

            @media screen and (min-width: $screen-sm) {
                position: absolute;
                top: 50%;
                transform: translate(-100%, -50%);
            }
        }

        .home:before {
            content: $fa-var-home;
        }

        .search:before {
            content: $fa-var-search;
        }
    }
}

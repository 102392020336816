﻿.topimage {
    margin: 0;
    float: none;
    padding: 0;

    .img-holder {
        img {
            @include img-responsive;
            width: 100%;
        }

        .container {
            position: relative;
        }
    }

    .text {
        position: absolute;
        top: 80px;
        left: 34px;
        margin: 0;
        font-size: 40px;
        font-weight: 700;
        text-shadow: 1px 1px #000000;


        @media screen and (max-width: $screen-xs-max) {
            font-size: $font-size-h4;
            top: 20px;
            left: 20px;
        }
    }

    .container-fluid.top-content-area & {

        @media screen and (max-width: $screen-xs-max) {
            margin-bottom: 15px;

            .text {
                top: 20px;
                left: 20px;
            }
        }
    }
}

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  font-style: normal;
  src: url('/Static/dist/fonts/Roboto-regular/Roboto-regular.eot');
  src: url('/Static/dist/fonts/Roboto-regular/Roboto-regular.eot?#iefix') format('embedded-opentype'),
       local('Roboto'),
       local('Roboto-regular'),
       url('/Static/dist/fonts/Roboto-regular/Roboto-regular.woff2') format('woff2'),
       url('/Static/dist/fonts/Roboto-regular/Roboto-regular.woff') format('woff'),
       url('/Static/dist/fonts/Roboto-regular/Roboto-regular.ttf') format('truetype'),
       url('/Static/dist/fonts/Roboto-regular/Roboto-regular.svg#Roboto') format('svg');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  font-style: normal;
  src: url('/Static/dist/fonts/Roboto-700/Roboto-700.eot');
  src: url('/Static/dist/fonts/Roboto-700/Roboto-700.eot?#iefix') format('embedded-opentype'), local('Roboto Bold'), local('Roboto-700'),
      url('/Static/dist/fonts/Roboto-700/Roboto-700.woff2') format('woff2'),
      url('/Static/dist/fonts/Roboto-700/Roboto-700.woff') format('woff'),
      url('/Static/dist/fonts/Roboto-700/Roboto-700.ttf') format('truetype'),
      url('/Static/dist/fonts/Roboto-700/Roboto-700.svg#Roboto') format('svg');
}

@font-face {
  font-family: 'Roboto Slab';
  font-weight: 700;
  font-style: normal;
  src: url('/Static/dist/fonts/Roboto-Slab-700/Roboto-Slab-700.eot');
  src: url('/Static/dist/fonts/Roboto-Slab-700/Roboto-Slab-700.eot?#iefix') format('embedded-opentype'),
       local('Roboto Slab Bold'),
       local('Roboto-Slab-700'),
       url('/Static/dist/fonts/Roboto-Slab-700/Roboto-Slab-700.woff2') format('woff2'),
       url('/Static/dist/fonts/Roboto-Slab-700/Roboto-Slab-700.woff') format('woff'),
       url('/Static/dist/fonts/Roboto-Slab-700/Roboto-Slab-700.ttf') format('truetype'),
       url('/Static/dist/fonts/Roboto-Slab-700/Roboto-Slab-700.svg#RobotoSlab') format('svg');
}

@font-face {
  font-family: 'Sansita One';
  font-weight: 400;
  font-style: normal;
  src: url('//Static/dist/dist/fonts/Sansita-One-regular.eot');
  src: url('//Static/dist/dist/fonts/Sansita-One-regular.eot?#iefix') format('embedded-opentype'),
       local('Sansita One'),
       local('Sansita-One-regular'),
       url('/Static/dist/dist/fonts/Sansita-One-regular.woff2') format('woff2'),
       url('/Static/dist/fonts/Sansita-One-regular.woff') format('woff'),
       url('/Static/dist/fonts/Sansita-One-regular.ttf') format('truetype'),
       url('/Static/dist/fonts/Sansita-One-regular.svg#SansitaOne') format('svg');
}


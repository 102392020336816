$share-icon-font-size: 16px !default;
$share-icon-size: 30px !default;
$share-icon-radius: $border-radius-base !default;

$share-facebook-square: "\f082";
$share-twitter-square: "\f081";
$share-linked-in-square: "\f08c";
$share-instagram: "\f16d";
$share-youtube-play: "\f16a";
$share-podcast: "\f130";

.share-icons {
  @include list-unstyled();
  @extend .list-inline;

  a {
    padding: 0;
    margin: 0;
    color: #fff;
    display: block;
    text-align: center;
    font-size: $share-icon-font-size;
    line-height: $share-icon-size;
    width: $share-icon-size;
    height: $share-icon-size;
    border: solid 1px transparent;
    border-radius: $share-icon-radius;

    @include gradient-vertical($start-color: $color-3, $end-color: $color-3, $start-percent: 0%, $end-percent: 100%);

    &:hover,
    &:focus {
      outline: 0;
      color: $color-3;
      border: solid 1px $color-3;

      &.instagram:before {
        color: $color-3;
      }

      @include gradient-vertical($start-color: $hover-background-color, $end-color: $hover-background-color, $start-percent: 0%, $end-percent: 100%);
    }

    &:focus {
      border: solid 1px $link-hover-color;
    }

    &:before {
      @extend .fa;
    }

    &.facebook:before {
      /*content: $fa-var-facebook;*/
      content: $share-facebook-square;
    }

    &.twitter:before {
      content: "";
      background: url(/Static/dist/img/vxa-x-white-icon.png) 2px 4px no-repeat;
      background-size: 100%;
      height: 32px;
      width: 29px;
      display: block;

      .footer-info & {
        background: url(/Static/dist/img/vxa-x-icon.png) 0 0 no-repeat;
        background-size: 100%;
        height: 25px;
        width: 25px;
        top: 2px;
        position: relative;

        @media screen and (min-width:992px) {
          height: 37px;
          width: 34px;
          top: 3px;
        }
      }
    }

    &.linkedin:before {
      /*content: $fa-var-linkedin;*/
      content: $share-linked-in-square;
    }

    &.instagram:before {
      content: $share-instagram;
      color: #fff;
    }

    &.youtube:before {
      content: $share-youtube-play;
    }

    &.podcast:before {
      content: $share-podcast;
    }

    &.email:before {
      content: $fa-var-envelope;
    }

    &.print:before {
      content: $fa-var-print;
    }
  }
}

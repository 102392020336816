﻿.url-not-accessable {
  display: none !important;
}
.url-not-accessable-edit-mode {
  position: relative;
  border: dashed 1px red;

  > * {
    opacity: .35;
  }

  &:before,
  &:after {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 15px;
    right: 15px;
    width: 100%;
    box-sizing: padding-box;
  }

  &:before {
    display: inline-block;
    @extend .fa;
    content: $fa-var-ban;
    font-size: 28px;
    padding-bottom: 10px;
    transform: translateY(-60%);
  }

  &:after {
    display: inline-block;
    content: "Länken existerar inte längre";
    transform: translateY(50%);
  }
}

.cookieconcent-youtube-placeholder {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;

  .col-sm-4 & {
    overflow: visible;
  }

  &::before {
    padding-top: 56.25%;
    display: block;
    content: "";
  }

  &::after {
    content: '';
    background: rgba(0,0,0,.4);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  &__image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }

  &__text {
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 9999;
    transform: translate(0, -50%);
    width: 100%;
    display: flex;
    flex-direction: column;
    color: #000;
    padding: 1em;
    background-color: #fff;
    font-size: 14px;

    @media only screen and (min-width: $screen-sm) {
      font-size: 18px;
      padding: 2em;
    }

    .col-sm-6 & {
      @media only screen and (min-width: $screen-sm) {
        font-size: 14px;
        padding: 1em;
      }
    }

    .col-sm-4 & {
      @media only screen and (min-width: $screen-sm) {
        font-size: 12px;
        padding: 1em;
      }

      @media only screen and (min-width: $screen-lg) {
        font-size: 14px;
        padding: 1em;
      }
    }
  }

  &__link {
      display: flex;
      align-items: center;
      margin-left: auto;
      text-decoration: underline;
  }

  svg {
      fill: $color-1;
      width: 40px;
      height: 40px;
  }
}

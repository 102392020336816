#mega-menu {
  margin: 0 ($grid-gutter-width/2*(-1));
  min-height: 0;

  @media screen and (max-width: $screen-sm-max) {
    margin: 0;
    width: 100%;
    left: 0;
    //top: 50px;
    z-index: 1000;

    .container {
      width: auto;
    }
  }


  #navbar-collapse-grid {
    text-align: left;
  }

  .navbar-nav {
    li {
      @media screen and (min-width: $grid-float-breakpoint) {
        position: static;
        //margin-right: 20px;
      }

      &.hide-non-mobile {
        width: 100%;

        a {
          font-size: 16px;
          display: flex;
          align-items: center;
        }

        .fa {
          width: 23px;
          height: 23px;
        }

        svg {
          width: 23px;
          height: 23px;
          fill: #fff;
          margin-right: 5px;
        }

        @media screen and (min-width: $screen-sm-max) {
          display: none;
        }
      }
    }
  }

  .navbar__li {
    @media screen and (max-width: $grid-float-breakpoint-max) {
      width: 100%;
    }

    @media screen and (min-width: $grid-float-breakpoint) {
      > .navbar__list-item {
        margin-right: 20px;
      }
    }
  }

  .mega-menu-panel-container {
    @media screen and (min-width: $grid-float-breakpoint) {
      display: none;
      position: absolute;
      left: 0;
      top: 100%;
      box-shadow: 1px 10px 19px 0px rgba(0,0,0,0.62);
      -webkit-box-shadow: 1px 10px 19px 0px rgba(0,0,0,0.62);
      -moz-box-shadow: 1px 10px 19px 0px rgba(0,0,0,0.62);
      box-sizing: border-box;
      padding-top: $grid-gutter-width;
      padding-bottom: $grid-gutter-width;
    }

    @media screen and (max-width: $grid-float-breakpoint-max) {
      display: none !important;
    }
  }

  @media screen and (min-width: $grid-float-breakpoint) {
    .mega-menu-open {
      .mega-menu-panel-container {
        display: block;
        background-color: $color-18;
        width: 100%;
      }

      .menu-list {
        opacity: 1;
      }
    }
  }

  a {
    font-size: 16px;
    color: $gradient-text;
    display: block;

    @media screen and (min-width: $grid-float-breakpoint) {
      font-size: 18px;
      padding: 10px 15px;
    }

    &.nav-item {
      border: 1px solid $color-tegel;
      border-radius: 6px;
    }

    &.navbar__list-item {
      border: 1px solid $color-tegel;
      border-radius: 6px;
      font-size: 16px;
      font-weight: 600;
      display: flex;

      svg.arrow {
        fill: $color-tegel;
        width: 24px;
        height: 24px;
        margin-left: 0.313rem;
        pointer-events: none;
      }

      @media screen and (max-width: $grid-float-breakpoint-max) {
        border: none;
        border-radius: 0;
        font-weight: 400;

        svg.arrow {
          display: none;
        }
      }
    }
  }

  .menu-list {
    @include make-sm-column(3);

    @media screen and (min-width: $grid-float-breakpoint) {
      //float: none;
      display: inline-block;
      text-align: left;
      transition: opacity ease 0.9s;
      opacity: 0;
    }

    .locked {
      > a,
      > span {
        &:before {
          padding-right: 0.3em;
          @extend .fa;
          content: $fa-var-lock;
        }
      }
    }

    ul {
      @include list-unstyled();
    }

    > ul {
      @media screen and (min-width: $grid-float-breakpoint) {
        margin-bottom: $grid-gutter-width/2;

        > li:first-child > a,
        > li:first-child > span {
          font-weight: bold;
        }
      }
    }
  }
}

/* CSS Document */
$blank-content:								"\00a0";
$border-color:                #ccc !default;
$content-bg:                  #f3f3f3 !default;
$footer-bg:                   $color-3 !default;

$spinkit-spinner-margin: 40px auto !default;
$spinkit-size: 40px !default;
$spinkit-spinner-color: $color-3 !default;


$color-primary:               $color-3 !default;
$color-primary-text:          $color-3-text !default;

$color-secondary:             $color-9 !default;
$color-secondary-text:        $color-9-text !default;

$date-color:                  #ccc;

$font-roboto-slab:            "Roboto Slab", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-sansita-one:            "Sansita One";
$font-weight-sansita-one:     400;

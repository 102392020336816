﻿$block-padding: 15px;

.campaignblock {
    .block-content {
        padding: $block-padding;
        background: #fff;
    }

    h2{
        margin-top:0;
         @media screen and (max-width: $screen-xs-max) {
            font-size: $font-size-h2*.70;
        }
    }
}

.campaignblock {

    .render-content {
        display: table;
        table-layout:fixed;
        width:100%;

        .render-text, 
        .render-image {
            vertical-align: top;
        }

        .render-text {
            display: table-cell;
            vertical-align:middle;
            width:70%
            
        }

        .render-image {
            display: table-cell;
            width: 30%;

            @media screen and (min-width: $screen-sm-min) {
                padding-top:10px;
                img {
                    @include img-responsive();
                }
            }



            @media screen and (max-width: $screen-xs-max) {
                display:table-header-group;
                
                img {
                      @include img-responsive();
                      margin-bottom: $grid-gutter-width/2;
                }
            }
        }
    }
}

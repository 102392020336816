// Main article page
// --------------------------------------------------


.main-article {
  header {
    @include clearfix();
     
    clear:both;
    position:relative;
    
    h1 {
    margin-top:0;
      @media screen and (max-width:$screen-xs-max) {
        font-size:$font-size-h1*0.7;
      }
    }
  }
  
  .article-date {}

  .top-img-holder{
      margin-bottom:30px;
  }
}
.editor{
    //Responsive images in editor
    img{
        max-width:100%;
        height:auto!important;
    } 
}
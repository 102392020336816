//
// Hidden skip link only visible when tabbing from adress field
// --------------------------------------------------

.skip-links {
  @include clearfix();
  position: absolute;
  top: -999px;
  left: 0;
  right: 0;
  z-index: 1999;
  width: 100%;

  a {
    background: $color-primary;
    display: block;
    position: absolute;
    text-align: center;
    top: 0;
    z-index: 9999;
    width: 100%;
    padding: 10px;

    &, &:hover, &:focus {
      color: $color-primary-text;
    }

    &:active, &:focus {
      top: 999px;
      outline: 0;
    }

    &[class="sr-only"] {
      visibility: hidden;
    }
  }
}
﻿.hooverblock {
    @extend .teaserblock;

    .block-content {
        a {
            @media screen and (min-width: $screen-sm) {
                .text {
                    transition: opacity .2s ease-in-out;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    opacity: 0;
                }

                .img-holder {
                    transition: opacity .2s ease-in-out;
                    opacity: 1;
                }


                &:hover, &:active, &:focus {
                    .text {
                        opacity: 1;

                        h2{
                            color:black;
                        }
                    }

                    .img-holder { 
                        opacity: 0;
                    }
                }
            }

            @media screen and (max-width: $screen-xs-max) {
                display:table;
                width:100%;
                table-layout:fixed;

                .text {
                    display: table-cell;
                    vertical-align: middle;
                    width: 75%;
                    background: #fff;
                }

                .img-holder {
                    display: table-cell;
                    width: 25%;
                }

                &:hover, &:active, &:focus {
                    .text {
                        background:none;

                        h2{
                            color:black;
                        }
                    }
                }
            }
        }
    }
}

@mixin fa-lgicon($size: 1.5em) {
  font-size: $size;
}

@mixin min-height($minHeight: 10px) {
  min-height: $minHeight;
  height: auto !important;
  height: $minHeight;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}
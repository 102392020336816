﻿.subnavigation-area {
  background-color: white;
  margin-bottom: 30px;

  .title {
    padding: 15px;
    background-color: #FCEED9;
    border-left: solid 3px #F08A00;
    font-size: 18px;
  }

  a {
    color: #333333;

    &:hover {
      color: #F08A00;
    }
  }

  .subnavigation-list-item {
    font-weight: normal;
  }

  .subnavigation-list-item:last-of-type {
  }

  .subnavigation-list-item.expanded {
    .link-icon-wrapper {
    }
  }

  .sub-pages {
    padding-left: 10px;
  }

  .link-icon-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 8px 15px 8px 22px;

    &.bold {
        font-weight: 600;
    }
  }

  .collapsed {
    display: none;
  }

  .expand-icon {
    font-size: 9px;
    position: relative;
    width: 15px;
    height: 15px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
    border: none;
    background: transparent;


    &:hover {
      &:before,
      &:after {
        background-color: #aa6000;
      }
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      background-color: #F08A00;
      transition: transform 0.25s ease-out;
    }
    /* Vertical line */
    &:before {
      top: 0;
      left: 50%;
      width: 3px;
      height: 100%;
      margin-left: -1.5px;
    }
    /* horizontal line */
    &:after {
      top: 50%;
      left: 0;
      width: 100%;
      height: 3px;
      margin-top: -1.5px;
    }
  }

  .expand-icon.icon-expanded {
    &:before {
      transform: rotate(90deg);
      opacity: 0;
    }

    &:after {
      transform: rotate(180deg);
    }
  }

  .link {
    width: auto;
  }

  #active-page {
    font-weight: bold;
  }

  .active-background-and-border {
    background-color: #FCEED9;
    border-left: 3px solid #F08A00;

    .link-icon-wrapper {
      margin-left: -3px;
    }
  }

  .subnavigation-list-item[aria-expanded=false] {
    .list-item-divider {
      opacity: 0;
    }
  }

  .sub-pages {
    .subnavigation-list-item {
      .list-item-divider {
        opacity: 0.3;
      }
    }
  }

  .list-item-divider {
    margin-top: 0;
    margin-bottom: 0;
    border-top: 1px solid #F08A00;
    opacity: 0.3;
  }
}

#mobile-subnavigation-container{
  display: none;
}

#mobile-subnavigation-container.show {
  display: initial;
  margin-bottom: 22px;

  .expand-mobile-subnavigation {
    font-size: 9px;
    width: 20px;
    height: 20px;
    border: solid #F08A00;
    border-width: 0 3px 3px 0;
    background-color: transparent;
    transform: rotate(45deg);
    transition: transform 0.15s ease-out;
  }

  .expand-mobile-subnavigation.expanded {
    transform: rotate(225deg);
    transition: transform 0.15s ease-out;
  }

  #expand-mobile-subnavigation:focus {
    outline: none;
  }



  #mobile-subnavigation-top {
    display: flex;
    padding: 5px 15px;
    border-left: solid 3px #F08A00;
    font-size: 18px;

    .mobile-subnavigation-information {
      margin: auto auto auto 0;

      h3 {
        margin-top: 0;
        margin-bottom: 5px;
      }
      span{
        font-size: 16px;
      }
    }

    .mobile-subnavigation-expand {
      margin: auto 0 auto auto;
    }

    .mobile-subnavigation-expand {
      margin-left: 15px;
    }
  }

  #mobile-subnavigation-bottom {
  }

  .collapsed {
    display: none;
  }
}

.article-footer {
    @include clearfix();
    clear: both;
    border-top: solid 1px $border-color;
    border-bottom: solid 1px $border-color;
    margin-bottom: $grid-gutter-width;

    .print-noscript {
        clear: both;
        position: relative;
        padding-left: 25px;
        margin: 10px 0 10px;
        font-size: $font-size-base *.9;

        &:before {
            @extend .fa;
            position: absolute;
            left: 0;
            top: 0px;
            width: 20px;
            height: 20px;
            text-align: center;
            padding: 0;
            content: $fa-var-info-circle;
            color: $color-1;
            font-size: 20px;
        }
    }
}

.article-info {
    @include clearfix();
    width: 100%;
    padding: 10px 0;
    line-height: 3.1rem;

    > * {
        @media screen and (min-width: $screen-sm) {
            margin: 0;
        }
    }

    p {
        @media screen and (min-width: $screen-sm) {
            float: left;
        }
    }

    ul {
        @extend .list-inline;

        @media screen and (min-width: $screen-sm) {
            float: right;
        }
    }
}

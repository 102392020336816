﻿.ajax-loader {
  z-index: 1001;
  opacity: 0;

  & {
    transition: opacity 1.0s ease-in-out;
  }

  &:not(.loader-over) {
    position: relative;
  }

  &.loader-over {
    &,
    &:before {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }

    &:before {
      z-index: 1001;
      content: $blank-content;
      background: rgba($content-bg, .5);
    }

    > div {
      position: fixed;
      z-index: 1002;
      //width:100%;
      //height:40px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &:not(.show) {
    overflow: hidden;
    width: 1px;
    height: 1px;
    left: 0;
    top: 0;
  }

  &.show {
    opacity: 1;
  }

  .sk-fading-circle {
    margin: 0px auto;
    width: 150px;
    height: 100px;
    position: relative;
  }

  &:not(.show) {
    .sk-fading-circle {
      width: 0px;
      height: 0px;
      overflow: hidden;
    }
  }
}
.load-more-hits-wrapper{
    .sk-fading-circle {
    margin: 0px auto;
  }
}


.load-more-hits-wrapper {
  opacity: 0;
  height:1px;
  transition: opacity .2 ease-in-out;

  &.loading {
    opacity: 1;
    height:auto;
  }
}
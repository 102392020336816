﻿// This is needed to vertically center the content in IE11
.jumboblock {
  display: flex;
  height: 100%;
  flex-direction: column;
  padding-right: 0;
  padding-left: 0;
}

.jumbo {
  $jumbo: &;
  position: relative;
  display: flex;
  flex-flow: column wrap;
  overflow: hidden;
  height: 60vh;
  max-height: 500px;
  color: #fff;
  font-size: 16px;

  @media screen and (min-width: $screen-md) {
    height: 400px;
  }

  &__media {
      position: absolute;
      z-index: 10;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;

      video {
          top: 0;
          height: 100%;
      }

      &:after {
          position: absolute;
          z-index: 0;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          content: "";
          opacity: 1;
          pointer-events: none;

          @at-root #{$jumbo}--overlay-dark#{$jumbo} & {
              background-color: #000;
              opacity: .25;
          }

          @at-root #{$jumbo}--overlay-light#{$jumbo} & {
              background-color: #fff;
              opacity: .25;
          }

          @at-root #{$jumbo}--overlay-lighter#{$jumbo} & {
              background-color: #fff;
              opacity: .45;
          }

          @at-root #{$jumbo}--overlay-orange#{$jumbo} & {
              background-color: $color-3;
              opacity: .25;
          }
      }
  }

  .object-fit {
      position: relative;
      z-index: -1;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
  }

  &--top {
    justify-content: flex-start;
  }

  &--middle {
    justify-content: center;
  }

  &--bottom {
    justify-content: flex-end;
  }

  &__content-container {
    position: relative;
    z-index: 30;
    display: flex;
    width: 100%;
    height: 100%;
    flex-flow: column;
    justify-content: flex-end;    
  }

  &__content {
    padding-top: 1.25em;
    padding-bottom: 1.25em;
    padding-left: 1em;
    padding-right: 1em;

    @media screen and (min-width: $screen-sm) {
      padding-top: 2em;
      padding-bottom: 2em;
      padding-left: 2em;
    }

    @media screen and (min-width: $screen-md) {
      width: 66.66%
    }    
  }

  &__gradient {
    padding-top: 2em;
    background: linear-gradient(to top, rgba(#000000, 0.75), rgba(#fff, 0));
  }

  &__heading {
    font-size: 30px;
    margin-bottom: 6px;

    @media screen and (min-width: $screen-sm) {
      font-size: 36px;
    }
  }
  
  &__text {
    font-size: 18px;

    @media screen and (min-width: $screen-sm) {
      font-size: 26px;
    }

  }
}

﻿$calendar-date-color: $color-1 !default;
$calendar-date-bg: #FFF !default;
$date-day-size: 40px;


.calendarblock {

    .event {
        margin-bottom: 10px;
        display: table;
        width: 100%;

        .row { //fix for centering the date
            position: relative;
        }

        .col-xs-4.col-sm-2 {
            position: initial;
        }
    }

    .text-container {
        display: table-cell;
         @media screen and (max-width: $screen-xs-max) {
             padding-left:15px;
         }
    }

    .date-container {
        display: table-cell;
        vertical-align: middle;
        text-align: center;

        p {
            margin: 0;
        }

        span {
            display: block;
        }
    }

    .date-day {
        font-size: $date-day-size;
        line-height: $date-day-size;
        height: $date-day-size;
        margin: 0 1em 0 0.8em;

        @media screen and (max-width: $screen-xs-max) {
            margin: 0 0.5em 0 0.5em;
            font-size:$date-day-size*0.7;
            line-height: $date-day-size*0.7;
            height: $date-day-size*0.7;
        }
    }

    h3 {
        margin-bottom: 5px;
        margin-top: 0;

        @media screen and (max-width: $screen-xs-max) {
            font-size: $font-size-h3*.70;
        }
    }

    .event-link {
        color: $text-color;
        padding: 15px;
        display: block;
        background: $calendar-date-bg;
        @include animate-bg-mixin();


        &:hover, &:focus {
            text-decoration: none;
            @include block-bg-hover-mixin();
        }
    }
}

﻿.block.faqblock {
  p {
    margin: 0;
  }
}

.faq-container {
  padding: 0;
  .row{
    margin: 0;
  }
}

.faq-intro-text {
    margin-left: 15px;
    margin-bottom: 15px;
  }

.faq-pairs {
  display: flex;
  flex-direction: column;
  background: white;
  padding: 10px 0px;
}

.question-answer-pairs {
  display: flex;
  flex-direction: column;
}

.faq-question-container {
  padding-bottom: 15px;
  border-bottom: 1px solid lightgrey;
}

.faq-question {
  display: flex;
  justify-content: left;
  font-size: 18px;
  font-weight: bold;

  svg {
      width: 27px;
      height: 27px;
      margin-right: 0.5rem;
    &.closed {
      transition: transform 0.1s linear;
      transform: rotate(0deg);
    }

    &.open {
      transition: transform 0.1s linear;
      transform: rotate(180deg);
    }
  }
}

.block.faqqapairblock {
  width: 100%;
  margin-bottom: 0;
  width: 100%;
  margin-bottom: 0;
  padding-right: 0px;
  padding-left: 0px;
}

.question-answer {
  width: 100%;
  background: white;
  margin: 0px;
  padding: 8px 20px;
  cursor: pointer;
  color: inherit;
  border: none;
  font: inherit;
  outline: inherit;
  text-align: left;

  &:hover {
    color: #F08A00;

    svg {
      fill: $color-orange;
    }
  }

  :focus {
    outline: none;
    color: #F08A00;

    svg {
      fill: $color-orange;
    }
  }
}

.faq-answer-container {
  color: #000;
  overflow: hidden;
  opacity: 0;
  max-height: 0;
  transition: 0.3s;
  margin: 0;
  padding: 0px 15px;
}

.faq-answer-container.show {
  opacity: 1;
  max-height: 500px;
  margin-top: 5px;
  padding: 5px 15px;

  .faq-answer {
    padding-top: 10px;
  }
}


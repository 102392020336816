﻿$control-btn-size-mobile: 25px;
$control-btn-size-desktop: 50px;


.slideshowblock {
    @include clearfix();

    .drop-carousel-top & {
        @media screen and (max-width:$screen-sm-max) {
            margin-bottom: $grid-gutter-width/2;
        }
    }
}



.wide-wrapper.drop-carousel-top {
    margin: 0;
    padding: 0;
}



.carousel {
    position: relative;

    a {
        &, & *, & *:before {
            transition: 0.2s, ease-out;
        }

        &:hover, &:hover *, &:hover *:before {
            transition: 0.2s, ease-in;
        }
    }

    a:not(.btn) {
        /*&, 
        &:hover, 
        &:focus {
            &, & * {
                @media screen and (max-width:$screen-xs-max) {
                    color: #000 !important;
                }

                @media screen and (min-width:$screen-sm) {
                    color: #fff !important;
                }
            }
        }*/

        &:hover, 
        &:focus {
            h1, h2, h3 {
                text-decoration: underline;
            }
        }
    }
}

.carousel-inner {
    position: relative;
    overflow: hidden;
    width: 100%;

    @media screen and (max-width:$screen-xs-max) {
        //padding-bottom:70px;
    }

    > .item {
        display: none;
        position: relative;
        transition: .6s ease-in-out left;
        // Account for jankitude on images
        > img,
        > a > img {
            @include img-responsive();
            line-height: 1;
        }

        img {
            @include img-responsive();
            width: 100%;
        }
        // WebKit CSS3 transforms for supported devices
        @media all and (transform-3d), (-webkit-transform-3d) {
            transition: transform .6s ease-in-out;
            backface-visibility: hidden;
            perspective: 1000;

            &.next,
            &.active.right {
                transform: translate3d(100%, 0, 0);
                left: 0;
            }

            &.prev,
            &.active.left {
                transform: translate3d(-100%, 0, 0);
                left: 0;
            }

            &.next.left,
            &.prev.right,
            &.active {
                transform: translate3d(0, 0, 0);
                left: 0;
            }
        }

        @media screen and (max-width: $screen-xs-max) {
            .col-xs-12 {
                padding: 0;
            }
        }
    }

    > .active,
    > .next,
    > .prev {
        display: block;
    }

    > .active {
        left: 0;
    }

    > .next,
    > .prev {
        position: absolute;
        top: 0;
        width: 100%;
    }

    > .next {
        left: 100%;
    }

    > .prev {
        left: -100%;
    }

    > .next.left,
    > .prev.right {
        left: 0;
    }

    > .active.left {
        left: -100%;
    }

    > .active.right {
        left: 100%;
    }

      @media screen and (max-width: $screen-sm-max) { 
          .container {
              width:auto;
              padding:0;
          }
      }
}



.carousel-control, .bx-controls-direction a {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: $control-btn-size-desktop;

  .slide-items-01 & {
    display: none !important;
  }

  @media screen and (max-width:$screen-xs-max) {
    width: $carousel-control-width/2;
  }

  opacity: $carousel-control-opacity;
  font-size: $carousel-control-font-size;
  color: $carousel-control-color;
  text-align: center;
  text-shadow: $carousel-text-shadow;

  .slideshowblock & {
    opacity: 1;
  }
  // Set gradients for backgrounds
  &.left,
  &.bx-prev {
    @include gradient-horizontal($start-color: rgba(0,0,0,.0), $end-color: rgba(0,0,0,.0), $start-percent: 0%, $end-percent: 100%);

    &:hover {
      @media screen and (min-width:$screen-sm) {
        @include gradient-horizontal($start-color: rgba(0,0,0,.25), $end-color: rgba(0,0,0,.0001), $start-percent: 0%, $end-percent: 100%);
      }
    }
  }

  &.right,
  &.bx-next {
    left: auto;
    right: 0;
    @include gradient-horizontal($start-color: rgba(0,0,0,.0), $end-color: rgba(0,0,0,.0), $start-percent: 0%, $end-percent: 100%);

    &:hover {
      @media screen and (min-width:$screen-sm) {
        @include gradient-horizontal($start-color: rgba(0,0,0,.0001), $end-color: rgba(0,0,0,.25), $start-percent: 0%, $end-percent: 100%);
      }
    }
  }

  &.left,
  &.right,
  &.bx-prev,
  &.bx-next {

    svg {
      position: absolute;
      top: 50%;
      left: 0;
      margin-left: -($control-btn-size-desktop);
      margin-top: -(($grid-gutter-width/2) + ($control-btn-size-mobile /2));
      width: $control-btn-size-mobile;
      height: $control-btn-size-mobile;
      line-height: $control-btn-size-mobile;
      fill: #fff;
      background: fade(#000, 0%);
      text-align: center;
      font-size: 25px;
      color: $color-white;

      .slideshowblock & {
        margin-left: 0;
      }

      @media screen and (min-width:$screen-sm) {
        width: $control-btn-size-desktop;
        height: $control-btn-size-desktop;
        line-height: $control-btn-size-desktop;
        font-size: 50px;
      }
    }

    .slideshowblock & {
      @media screen and (max-width:$screen-md-max) {
        opacity: 1;

        &:before {
          color: #fff;
          text-shadow: 0 0 0 rgba(0,0,0,0);
        }

        &:hover,
        &:focus {
          &:before {
            color: #fff;
          }
        }
      }
    }
  }

  &.right, &.bx-next {
    svg {
      left: auto;
      right: 0;
      margin-right: -($control-btn-size-desktop);

      .slideshowblock & {
        margin-right: 0;
      }
    }
  }

  @media screen and (max-width:$screen-sm-max) {

    &.left:before, &.bx-prev:before {
      left: $grid-gutter-width/2;

      .main-article .slideshowblock & {
        left: 0;
      }
    }

    &.right:before, &.bx-next:before {
      right: $grid-gutter-width/2;

      .main-article .slideshowblock & {
        right: 0;
      }
    }
  }
  // Hover/focus state
  &:hover,
  &:focus {
    outline: 0;
    color: $carousel-control-color;
    text-decoration: none;
    opacity: 1;

    &.left svg,
    &.right svg,
    &.bx-prev svg,
    &.bx-next svg {
      color: #fff;
      background: shade($color-3, 15%);
    }
  }
}



.carousel-indicators {
    display: none;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 15;
    width: auto;
    margin: 0;
    padding-left: 0;
    list-style: none;
    text-align: center;

    .slide-items-01 & {
        display: none !important;
    }

    li {
        display: inline-block;
        width: 16px;
        height: 16px;
        margin: 1px;
        text-indent: -999px;
        border: 0; //1px solid $carousel-indicator-border-color;
        border-radius: 16px;
        cursor: pointer;
        background-color: rgba(#fff, .5);

        @media screen and (max-width: $screen-xs-max) {
            background-color: rgba(#000, .2);
        }

        &.active {
            margin: 1px;
            width: 16px;
            height: 16px;
            background-color: $carousel-indicator-active-bg !important;
        }
    }

    @media screen and (min-width: $screen-sm-min) {
        .carousel-indicators {
            bottom: 20px;
        }
    }

    @media screen and (max-width:$screen-sm-max) {
        margin-bottom: 0;
    }

    .bodynotstart .drop-carousel-top & {
        @media screen and (min-width: $screen-sm) {
            display: none;
        }
    }
}

.carousel-caption {
    left: 0;
    text-align: left;
    padding-left: 30px;
    overflow: auto;
    //text-shadow: 1px 1px #000000;
    & .btn {
        text-shadow: none; // No shadow for button elements in carousel-caption
        @media screen and (min-width:$screen-sm) {
            margin-bottom: 15px;
        }

        @media screen and (max-width:$screen-md) {
            margin-top: 10px;
        }

        @media screen and (max-width:$screen-md) and (min-width:$screen-sm) {
            position: relative;
            bottom: 15px;
            margin-top: 25px;
        }
    }

    h1, h2, h3, p {
        font-size: $font-size-h1;
    }

    @media screen and (max-width: $screen-sm-max) {
        background: white;
        text-shadow: none;

        h1, h2, h3, p {
            font-size: $font-size-h4;
            color: black
        }

        p {
            margin-bottom: 0;
            color: black
        }
    }

    @media screen and (max-width: $screen-sm-max) {
        position: static;
        padding: 15px 30px;
    }
} 

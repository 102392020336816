﻿.imageblock {
    position: relative;

    > div {
        @include block-radius-mixin();

        @media screen and (max-width: $screen-xs-max) {
            @include block-bg-mixin();
        }
    }

    a {
        position: relative;
        display: block;
        color: $text-color;


        .img-holder {opacity:1; transition:opacity .1s ease }

        &:hover,
        &:focus {
            color: $text-color;
            text-decoration: none;

            h2 {
                //text-decoration: underline;
            }

            .img-holder {opacity:0.8; }
        }
    }

    .img-holder {
        img {
            @include img-responsive;
            width: 100%;
        }
    }

    .text {
        padding: $grid-gutter-width/2;

        h2 {
           
            font-size: 40px;
            
        }

        em {
            font-weight: bold;
            font-style: normal;
            text-decoration: underline;
        }

        @media screen and (min-width: $screen-sm) {
            position: absolute;
            top: $grid-gutter-width;
            left: $grid-gutter-width;
            padding: 0;
            width: 66%;
        }

        @media screen and (max-width: $screen-xs-max) {
            h2, p {
                font-size: $font-size-h4;
                margin: 0;
            }
        }
    }

    .jumbotron-img {
        .text {
            h2 {
                font-size: $font-size-h3;

                @media screen and (min-width: $screen-sm) {
                    font-size: 67px;
                }
            }

            p {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.container-fluid {
    .imageblock {
        padding: 0;
    }
}

﻿.filter-dates
{
    input[type="text"]
    {
        /*width: 166px;*/
        display: inline-block;
    }
    button {
        @extend .btn, .btn-xs, .btn-primary;
        &:before {
            @extend .fa;
            content: $fa-var-close;
        }
    }
}
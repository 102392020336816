﻿.calendar-filter-holder {
    @include block-radius-mixin();
    overflow: auto;
}

.calendar-filter-holder {
  color: $text-color;
  padding: 0 15px;
  margin: $grid-gutter-width 0;
  clear: both;
  display: table;
  table-layout: fixed;
  width: 100%;
  @include clearfix();

  @media screen and (max-width: $screen-xs-max) {
    display: block; 
  }

  .calendar,
  .calendar-filter {
    padding: 1em;
    display: table-cell;
    width: 50%;  
    background: #fff;
    vertical-align: top;

    @media screen and (max-width: $screen-xs-max) {
      display: block; 
      clear: both;
      width: 100%;
    }

    @media screen and (max-width: $screen-sm-max) {
      & {
        td {
          padding: 2px;
        }
      }
    }
  }

  .calendar {
    @media screen and (max-width: $screen-xs-max) {
      display: none !important; /* Hide calender in mobile layout */
    }
  }

  .calendar-filter {
    background: #fff;
    padding: 1em 1em 1.5em 1em !important;

    label {
      color: #000;
    }
  }

  .list-footer {
    height: 30px;
  }


  .ui-datepicker-header {
    position: relative;
    background: #fff;
    text-align: center;
    margin-top: -($grid-gutter-width/3);

    .ui-icon {
      color: #fff;
    }

    .ui-datepicker-title {
      display: block;
      font-family: $headings-font-family;
      font-weight: $headings-font-weight;
      font-size: $font-size-h4;
      padding: $grid-gutter-width/3 0;
    }

    .ui-datepicker-prev,
    .ui-datepicker-next {
      color: rgba(#000, 1);

      span {
        display: none;
      }

      &:hover,
      &:focus {
        color: rgba(#000, .5);
        cursor: pointer;
      }

      &:before {
        position: absolute;
        @extend .fa;
        font-size: 3em;
        top: 50%;
        transform: translateY(-50%);
      }

      &.ui-datepicker-prev:before {
        content: $fa-var-angle-left;
        left: 0;
      }

      &.ui-datepicker-next:before {
        content: $fa-var-angle-right;
        right: 0;
      }
    }
  }

  .ui-datepicker-calendar,
  #ui-datepicker-div table {
    background: #fff;
    border-collapse: collapse;
    border: solid 1px $table-border-color;
    width: 100%;
    max-width: 100%;
    margin: 0;

    > tbody > tr:nth-child(odd), > tbody > tr:nth-child(even) {
      background-color: $color-18;
    }

    th,
    td {
      text-align: center;
      border: none;
    }

    th {
      color: #000;
      background: $color-14;
      padding: 0.8em;
      border: none;

      @media screen and (max-width: $screen-xs-max) {
        padding: 0.6em;
      }
    }

    a {
      transition: all ease-in-out .08s;
      text-decoration: none;
      color: #000;
      display: inline-block;
      min-width: 38px;
      line-height: 36px;
      border-radius: 50%;
      background-color: #fff;
      border: solid 1px #fff;

      @media screen and (max-width: $screen-xs-max) {
        min-width: 20px;
        line-height: 20px;
      }
    }
    //Vanlig dag
    .ui-state-default {
      /*Hoover vanlig dag*/
      &.ui-state-hover {
        background-color: gray;
        border-color: gray;
        color: #000;
      }
    }
    //Vald dag
    .ui-state-active {
      background-color: $color-3;
      border-color: $color-3;
      color: #fff;


      &.ui-state-hover {
        background-color: #fff;
        border-color: $color-3;
        color: $color-3;
      }
    }
    //Har event
    .has-event {
      a {
        background-color: #fff;
        border-color: $color-3;
        color: $color-3;

        &.ui-state-hover {
          background-color: $color-3;
          border-color: $color-3;
          color: #fff;
        }

        &.ui-state-active {
          background-color: $color-3;
          border-color: $color-3;
          color: #fff;

          &.ui-state-hover {
            background-color: #fff;
            border-color: $color-3;
            color: $color-3;
          }
        }
      }
    }
    //Idag
    .ui-datepicker-today {
      a {
        background-color: $color-tegel;
        color: #fff;
        border: solid 1px $color-tegel;

        &.ui-state-hover {
          background-color: shade($color-tegel, 20%);
          border-color: shade($color-tegel, 20%);
          color: #fff;
        }
      }

      &.ui-datepicker-current-day {
        a {
          background-color: $color-tegel;
          color: #fff;
          border: solid 1px $color-tegel;

          &.ui-state-hover {
            background-color: shade($color-tegel, 20%);
            border-color: shade($color-tegel, 20%);
            color: #fff;
          }
        }
      }
    }
  }

  .ui-datepicker-other-month {
    background-color: $color-18;

    a {
      background-color: transparent;
    }
  }
}

#ui-datepicker-div {
    @extend .calendar-filter-holder;
    //background: $color-3;
    z-index: 99 !important;
    border: solid 1px $border-color;
    box-shadow: 0 5px 15px -2px rgba(0, 0, 0, 0.25);
    position: relative;
    display: inline-block;
    width: auto;
    padding: 0;
    margin: 0;
    display: none;

    .ui-datepicker-header {
        margin: 0;

        a {
            &:before {
                font-size: 28px !important;
                padding: 0 0.2em;
            }
        }
    }

    .ui-datepicker-title {
        margin: 0 !important;
    }

    table {
        margin-bottom: 0;
        width: auto;

        td {
            padding: 2px;
            border: none;
        }
    }
}

.filter-summary {
    color: $text-color;
    /*background: #fff;*/
    margin-bottom: $grid-gutter-width;
    padding: $grid-gutter-width/2 0 0 0;
    border-bottom: solid 3px $color-17;

    ul {
        @include list-unstyled();
        margin: 0;
        @include clearfix();

        li {
            float: left;
            margin-right: 1em;

            &:last-child {
                float: right;

                a {
                    &:after {
                        @extend .fa;
                        content: $fa-var-remove;
                        margin-left: 0.2em;
                    }
                }

                @media screen and (max-width: $screen-sm-max) {
                    display: block;
                    float: none;
                    clear: left;
                }
            }
        }
    }
}

.multiselect-dropdown {
  width: 100%;
  padding: 0;

  option {
    padding: 10px;
  }

}

.search-result-header {
    display: inline-block;
    /*margin-left: -15px;*/
}

#cf_datepicker {
    .ui-datepicker-header {
        margin-bottom: 10px;
    }
}

#activeFilters {
    .btnActivity, btnLocation {
        margin-top: 10px;
    }
}

.selectedActivities, .selectedLocations{
    text-align:right;
}

﻿body {
  background-color: #fff;

  &.episerver-editor {
    background: $content-bg;
  }
}


.wide-bg-01 {
    background: $content-bg; //shade($content-bg, 15%);
}


.page-content {
    background: $content-bg;

    .content-start { //Startpage only
        min-height: 600px;
        padding-bottom: $grid-gutter-width;

        .slideshowblock { //to make the icon links float on top of carousel
            float: none;

            @media screen and (min-width: $screen-sm-min) {
                margin-bottom: 0;
            }

            padding: 0; //make block all fullscreen
        }
    }
}


.template-info {
    > div {
        margin-top: $grid-gutter-width*1.5;
        padding-left: $grid-gutter-width/2;
        border-left: 5px solid $color-primary;
    }
}

.container-fluid.campaign-content-area {
    background-color: #fff;
    margin-bottom: 1em;

    &.transparent {
        background-color: transparent;
    }

    .block-content { //remove padding when inside container-fluid.campaign-content-area
        padding: 15px 0;
    }

    @media screen and (max-width:$screen-xs-max) {
        margin-top: 15px;
    }
}

.container-fluid.contact-list-block {
  margin-bottom: 1em;

  &.transparent {
    background-color: transparent;
  }

  .block-content { //remove padding when inside container-fluid.campaign-content-area
    padding: 15px 0;
  }

  @media screen and (max-width:$screen-xs-max) {
    margin-top: 15px;
  }
}

.breadcrumb {
    padding: 15px 0;
    margin: 0 0 25px 0;
    border-bottom: solid 1px $border-color;
    border-radius: 0;
}
  //CSS for hiding all li before .customrootpage
  .customrootpage li {
    display: none;
  }

  .customrootpage li.isRoot {
    display: inline-block;
  }

  .customrootpage .isRoot,
  .customrootpage .isRoot ~ li {
    display: inline-block;
  }

  .customrootpage .isRoot{
    &:before{
      content:none;
    }
  }
  //End


.member-page {
  .right-content {
    margin-top: 0;
  }
}

﻿// ------ {{ Teaser }} ------ //

.teaserblock {

    /*&:nth-of-type(4n+1) {
        clear: left;
    }*/

    &-header {
      display: flex;
      align-items: center;
      margin-top: .5em;
      margin-bottom: 1em;

      >  svg {
        flex: 0 0 1.5em;
        width: 1.5em;
        height: 1.5em;
        margin-right: .25em;
      }

    }

    &-divider {
      height: 5px;
    }

    a {
        display: block;
        color: $text-color;
        background-color: $color-white;

        &:hover,
        &:focus {
            color: $text-color;
            text-decoration: none;

            h2 {
                text-decoration: underline;
            }

            
        }
    }

    .img-holder {
        img {
            @include img-responsive();
            width: 100%;
        }
    }

    h2 {
      flex: 1;  
      font-size: $font-size-h4;
      margin: 0;

        @media screen and (max-width: $screen-xs-max) {
            /*font-size: $font-size-h3*.85;*/
            border-bottom: 0;
        }

        & + p {
            margin-top: 10px;
        }
    }
   
    p:last-of-type {
        margin-bottom: 0;
    }

    .block-content {
        //@include block-radius-mixin();

        &:not(.floating-text) {
            @include block-bg-mixin();
        }

        .text {
            padding: $block-padding/2;

            @media screen and (max-width: $screen-xs-max) {
                padding: $block-padding/2;
            }
        }
    }

    &.col-sm-3 {
        h2 {
            @media screen and (min-width: $screen-sm) {
                font-size: $font-size-h4;
            }
        }
    }

    .floating-text {
        position: relative;

        a {
            color: #fff;

            &:hover,
            &:focus {
                color: #fff;

                h2 {
                    color: $link-hover-color;
                    text-decoration: underline;
                }
            }
        }

        .text {
            position: absolute;
            left: 0;
            right: 0;
            width: 100%;
            bottom: 0;
            box-sizing: border-box;
            @include gradient-vertical($start-color: rgba(0, 0, 0, 0), $end-color: rgba(0,0,0,.75), $start-percent: 0%, $end-percent: 100%);

            > p {
                display: none;
            }
        }
    }

    /*&.col-sm-4:not(.floating-text) .teaser-block {
        @media screen and (min-width: $screen-sm) {
            @include min-height(219px);
        }

        @media screen and (min-width: $screen-md) {
            @include min-height(293px);
        }

        @media screen and (min-width: $screen-lg) {
            @include min-height(360px);
        }
    }*/

    &.teaserblock:not(.hooverblock) {
        p {
            min-height:150px;
             @media screen and (max-width: $screen-xs-max) {
                min-height:100px;
            }
        }
    }
}

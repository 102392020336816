/* CSS Document */

.list-item {
  float: left;
  width: 100%;
  /*border-top: solid 1px $border-color;*/
  &:first-of-type {
    border: none;
  }

  .text-container {
    display: table-cell;

    @media screen and (max-width: $screen-xs-max) {
      padding-left: 15px;
    }
  }

  .date-container {
    display: table-cell;
    vertical-align: middle;
    text-align: center;

    p {
      margin: 0;
    }

    span {
      display: block;
    }
  }

  .date-day {
    font-size: $date-day-size;
    line-height: $date-day-size;
    height: $date-day-size;
    margin: 0 1em 0 0.8em;

    @media screen and (max-width: $screen-xs-max) {
      margin: 0 0.5em 0 0.5em;
      font-size: $date-day-size*0.7;
      line-height: $date-day-size*0.7;
      height: $date-day-size*0.7;
    }
  }



  .date {
    margin: 0 0 .5em;
  }

  &.advert {
    background: none;
    padding: 0;

    a {
      padding: 0;

      &:hover {
        background: none;

        &:after {
          content: none;
        }
      }
    }

    &.half {
      width: 50%;

      &.left {
        a {
          padding-right: 10px;
        }
      }

      &.right {
        a {
          padding-left: 10px;
        }
      }
    }

    img {
      width: 100%;
    }
  }
}

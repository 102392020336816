﻿$menu-color:                #000 !default;
$menu-bg:                   #fff !default;

$menu-active-color:         $color-primary;


// ----- 

$nav-vert-color:            $text-color;
$nav-vert-bg:               $color-16;

$nav-vert-open-color:       $nav-vert-color !default;
$nav-vert-open-bg:          #fff !default;

$nav-vert-active-color:     $menu-color !default;
$nav-vert-active-bg:        $menu-bg !default;

$nav-vert-border:           $menu-bg !default;


$mobile-toggle-btn-icon:            $color-nav-collapse-btn; //$color-3 !default;
$mobile-toggle-btn-color:           #fff !default;
$mobile-toggle-btn-bg:              transparent !default;
$mobile-toggle-btn-color-active:    #fff !default;
$mobile-toggle-btn-bg-active:       transparent !default;



// ----- 


$mobile-nav-color:            #fff !default;
$mobile-nav-bg:               $color-nav-mobile-bg; //$color-3 !default;
$mobile-nav-active-color:     $mobile-nav-color !default;
$mobile-nav-active-bg:        $mobile-nav-bg !default;


$mobile-nav-color-2:          $nav-vert-color !default;
$mobile-nav-bg-2:             $nav-vert-bg !default;
$mobile-nav-active-color-2:   $mobile-nav-active-color !default;
$mobile-nav-active-bg-2:      tint($mobile-nav-active-bg, 25%) !default;


$mobile-nav-color-3:          $text-color !default;
$mobile-nav-bg-3:             #fff !default;
$mobile-nav-active-color-3:   $mobile-nav-color-2 !default;
$mobile-nav-active-bg-3:      shade($mobile-nav-bg-2, 25%) !default;



$mobile-nav-border:         tint($mobile-nav-bg, 20%) !default;
$mobile-nav-border-2:       shade($mobile-nav-bg-2, 10%) !default;
$mobile-nav-border-3:       shade($mobile-nav-bg-3, 20%) !default;

$mobile-nav-height:         50px !default;

// ----- IMAGE Formats
.img-left, 
.img-right {
	@media screen and (max-width:$screen-xs-max) {max-width:50%;}	
}
.img-left {
  float: left;
  margin: 0 20px 20px 0;

  @media screen and (max-width:$screen-xs-max) {
    float: none;
    display: block;
    margin: 0 0 15px;
    //margin-right:10px;
  }
}
.img-right {
  float: right;
  margin: 0 0 20px 20px;

  @media screen and (max-width:$screen-xs-max) {
    float: none;
    display: block;
    margin: 0 0 15px;
  }
}
﻿.alertblock {
  @extend .teaserblock;
  margin-top: $grid-gutter-width;
  margin-bottom: $grid-gutter-width;

  @media screen and (max-width: $screen-xs-max) {
    margin-top: $grid-gutter-width/2;
  }

  .collapse {
    p {
      cursor: pointer;
    }
  }

  .collapse:not(.in) {
    display: block;
    height: 0px;

    p {
      max-width: 85%;
      position: relative;
      height: 15px !important;
    }
  }



  @media screen and (max-width: 520px) and (min-width: 341px) {
    .collapse:not(.in) {
      display: block;


      p {
        max-width: 72% !important;
        position: relative;
        height: 15px !important;
      }
    }
  }


  @media screen and (max-width: 340px) {
    .collapse:not(.in) {
      display: block;
      height: 0px;

      p {
        max-width: 66% !important;
        position: relative;
        height: 15px !important;
      }
    }
  }



  .block-content {
    position: relative;

    h2 {
      font-size: $font-size-h3;
      margin: 0;



      @media screen and (max-width: $screen-xs-max) {
        font-size: $font-size-h3 * .65;
      }
    }

    .text {
      padding: 0 0 0 80px;

      @media screen and (max-width: $screen-xs-max) {
        padding-left: 50px;
      }

      div p {
        padding: 12px 15px 15px 15px;
      }
    }

    a {
      color: #000;

      &:hover,
      &:focus {
        color: $link-hover-color;
      }

      &:after {
        height: 0px;
        content: "";
      }
    }

    .hide-alertmessage {
      position: absolute;

      svg {
        position: absolute;
        left: -20px;
        top: 20%;
        fill: $gray-light;
        width: 30px;
        height: 30px;

      }

      &:hover,
      &:focus {
        svg {
          fill: $color-orange;
        }
      }
    }

    &:before {
      content: $blank-content;
      display: inline-block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 80px;

      @media screen and (max-width: $screen-xs-max) {
        width: 50px;
      }
    }

    svg {
      fill: $color-orange;
      width: 60px;
      height: 60px;
      position: absolute;
      top: 50%;
      left: 40px;
      font-size: 30px;
      transform: translate(-50%, -50%);
      text-align: center;

      @media screen and (max-width: $screen-xs-max) {
        left: 25px;
        font-size: 24px;
      }
    }
  }

  .close-button-container {
    height: 65px;

    button {
      margin-right: $grid-gutter-width/2;
      margin-top: $grid-gutter-width/2;
    }
  }
}

.alert-message-topholder {
  padding: 15px;
  position: relative;
  transition: 0.3s;

  h2 {
    display: inline-block;
    max-width: 88%;
  }

  .hide-alertmessage {
    position: absolute;
    display: inline-block;
    position: absolute;
    right: 0px;
    padding-bottom: 30px;
  }
}

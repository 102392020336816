body .EPiServerForms {
  $active-color: rgba(41,128,189,0.5);
  $invisibleelement-color: rgba(184, 192, 197, 0.6);
  $border-radius: 4px;
  $warning-foreground-color: red;
  $warning-background-color: #fff8aa;

  // http://ux.episerver.com/#colors
  $primary1: #84c8e4;

  .Form__Title {
  }

  .Form__Description {
    margin-bottom: 1rem;
  }

  .Form__Status {
    .Form__Status__Message {
      display: block;
      padding: 1rem;
      margin: 1rem 0;
    }

    .Form__Success__Message {
      background-color: #fff; //$primary1;
      color: $text-color;
      border: solid 1px #d2d2d2;
    }

    .Form__Warning__Message {
      background-color: $warning-background-color;
      color: $warning-foreground-color;
    }
  }

  .Form__MainBody {
  }
  // for next or previous navigation between steps
  .Form__NavigationBar {
    .Form__NavigationBar__Action {
      display: inline-block;
      padding: inherit;
      vertical-align: middle;
      text-align: center;
      border: 1px solid $active-color;
      border-radius: $border-radius;
      font-size: inherit;
      height: 2em;
      width: 10em;
      max-width: 10rem;
      color: initial;

      @include gradient-vertical($start-color: #fff, $end-color: #e6e6e6, $start-percent: 0%, $end-percent: 100%);

      &:disabled {
        color: GrayText;
      }
    }

    .Form__NavigationBar__ProgressBar {
      display: inline-block;
      border: 1px solid $active-color;
      vertical-align: middle;
      width: 40%;

      .Form__NavigationBar__ProgressBar--Progress {
        /*display: none;*/ /*turn on this style to hide visual progress bar*/
        background-color: $active-color;
        width: 0%; /* Will be adjusted by JavaScript */
        height: 1rem;
      }

      .Form__NavigationBar__ProgressBar--Text {
        display: none; /*turn off this style to show text "Page 1/4" of progress bar*/
        .Form__NavigationBar__ProgressBar__ProgressLabel {
        }

        .Form__NavigationBar__ProgressBar__CurrentStep {
        }

        .Form__NavigationBar__ProgressBar__StepsCount {
        }
      }
    }
  }

  .FormStep {
    .FormStep__Title {
    }

    .FormStep__Description {
      margin-bottom: 1rem;
    }
  }
  // All Form's Element need to be tag with this class at root DOM node. Form__Element wraps every-DOM-nodes of current form element.
  // For simple element like Textbox, it can be the input
  // For complex element like Selection, Captcha, it contains the Label and input controls for taking value from Visitor
  .Form__Element {
    margin: 0 0 0.8333333333rem 0;

    textarea,
    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="number"]{
      @extend .form-control;
    }
    // change style of Form__Element in the Preview viewport in EditView (it affects both Editting mode and Preview mode)
    .preview & {
    }
    // label for element
    .Form__Element__Caption {
      display: inline-block;
      min-width: 100%;
      vertical-align: top;
    }
    // validation error message for each Element
    .Form__Element__ValidationError {
      color: $warning-foreground-color;
      display: block;
      word-wrap: break-word;
      display:none;
    }
    // SEMANTIC
    // for marking non-data element (like FormStep, ParagraphText, ...)
    .Form__Element--NonData {
    }
  }
  /************* BUILDIN ELEMENTS ***********/
  /******************************************/
  .FormSubmitButton {
  }

  .FormResetButton {
  }

  .FormTextbox {
    .FormTextbox__Input {
    }
  }

  .FormTextbox--Textarea {
    .FormTextbox__Input {
    }
  }

  .FormTextbox--Number {
    .FormTextbox__Input {
    }
  }

  .FormHidden {
  }

  .FormFileUpload {
  }

  .FormCaptcha {
    .FormTextbox__Input {
      width: 8rem;
    }

    .FormCaptcha__Image {
    }

    .FormCaptcha__Refresh {
      font-size: 1em;
      width: auto;
      height: auto;
    }
  }

  .FormSelection {
  }

  .FormChoice {
    .FormChoice__Input {
    }

    .FormChoice__Input--Checkbox {
    }

    .FormChoice__Input--Radio {
    }
  }

  .FormChoice--Image {
    // hide the input (checkbox or radio) next to the image
    .hidden {
      visibility: hidden;
    }

    .FormChoice--Image__Item {
      /* for image choice items, display as a flow (inline-block), we want max 2 items in one row, with a small margin */
      display: inline-block;
      max-width: 40%;
      text-align: center; /* image caption will be center */
      margin: 0.5rem;

      .FormChoice--Image__Item__Caption {
        display: block;
        margin-bottom: .2rem;
      }
    }
    /*img stand next to checked image will be bordered*/
    .FormChoice__Input:checked ~ img {
      border: 5px solid $active-color;
    }
  }
  // Range element
  .FormRange {
    // range input type element
    .FormRange__Input {
      max-width: 60%;
      display: inline;
    }
    // min, max label elements
    .FormRange__Min {
    }

    .FormRange__Max {
    }
  }
  /*************** EDIT VIEW ****************/
  /******************************************/
  @mixin Warning {
    color: $warning-foreground-color;
    background-color: $warning-background-color;
    display: block;
    word-wrap: break-word;
  }
  // Form__Element which is invisible in ViewMode will have this CSS class in EditView (E.g.: FormStep and FormHidden should have this class)
  .EditView__InvisibleElement {
    &.FormStep {
      display: block;
      border-radius: $border-radius;
      border-color: $invisibleelement-color;
      background-color: $invisibleelement-color;
      color: #000;
      padding: 0.2rem;
      font-family: Verdana;
      box-shadow: 3px 3px 5px #ccc;
      /*text-align: center;*/
      .FormStep__Warning {
        @include Warning;
      }
    }

    &.FormHidden {
      display: inline-block;
      border-radius: $border-radius;
      border: 1.5px outset $invisibleelement-color;
      padding: 0.2rem 1rem;
      min-width: 18rem;
      /*text-align: center;*/
    }
  }

  .Form__Warning {
    @include Warning;
  }

  input[type="text"],
  textarea {
    width: 100%;
  }

  .hide {
    display: none !important;
  }
}


.FormSubmitButton {
  @extend .btn;
  @extend .btn-default;
}

.customformcontainerblock,
.formcontainerblock {
  > .Form__Title {
    &::before {
      @extend .fa;
      content: $fa-var-comment-o;
      font-size: 25px;
      position: absolute;
      left: 25px;
      top: 10px;
      transform: translateX(-50%);
    }

    @media screen and (max-width: $screen-xs-max) {
      display: block;
      position: relative;
      text-align: left;
      padding: 12px 15px 12px 50px;
      margin: 0;
      background: #fff;
      border-radius: $btn-border-radius-base;
    }

    @media screen and (min-width: $screen-sm) {
      &::before {
        display: none !important;
      }
    }
  }

  .toggle-container {
    @media screen and (max-width: $screen-xs-max) {
      form {
        padding: $grid-gutter-width/2;
        background: #fff;
        box-shadow: inset 0 4px 8px -4px rgba(0, 0, 0, 0.4)
      }
    }

    @media screen and (min-width: $screen-sm) {
      display: block !important;
      height: auto !important;
    }
  }
}

body .EPiServerForms .ValidationFail {
    .Form__Element__Caption {
        color: red;
        font-weight: normal;

        &:after {
            font-family: FontAwesome;
            content: $fa-var-exclamation-circle;
            margin-left: 2px;
        }
    }

    textarea, input[type=text], input[type=number] {
        border: solid 1px red;
        background: rgba(255, 0, 0, 0.12);
    }

    .Form__Element__ValidationError {
        display: none !important;
    }
}



body .EPiServerForms .ValidationSuccess {
    &:not(.FormChoice) {
        &.ValidationRequired, &:not(.FormTextbox--Number) {
            .Form__Element__Caption {
                color: green;
                font-weight: normal;

                &:after {
                    font-family: FontAwesome;
                    content: $fa-var-check;
                    margin-left: 2px;
                }
            }

            textarea, input[type=text] {
                border: solid 1px green;
                background: rgba(0, 255, 0, 0.12);
            }
        }
    }
}

.EPiServerForms .ValidationRequired .Form__Element__Caption:after{
    display:none;
}

#header {
  @include clearfix();
  background: #fff;
  position: relative;
  z-index: 300;

  .head {
    position: relative;
    z-index: 2;
    padding-top: 15px;
    padding-bottom: 15px;

    @media screen and (min-width: $screen-sm) {
      padding-top: 25px;
      padding-bottom: 25px;
    }

    @media screen and (min-width: $screen-lg) {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
}

.logo {
  z-index: 3;
  float: left;

  .notkott &, .husdjur & {
    max-width: 160px;
  }

  img {
    @include img-responsive;
    width: 100%;

    @media screen and (max-width: $screen-xs-max) {
      max-width: 120px;
      display: inline-block;
    }
  }

  a {
    display: block;
  }

  svg {
    max-width: 100%;
    height: 27px;

    @media screen and (min-width: $screen-sm) {
      height: 40px;
    }

    @media screen and (min-width: $screen-lg) {
      height: 55px;
    }
  }
}

#login-form-wrapper, #quick-search-wrapper {
  .dropdown-menu {
    @include border-radius(0px);
    min-width: 315px;
    padding: 15px;
    margin-top: 15px;
  }

  .btn-link {
    color: #000;
  }

  .forgot-password {
    position: absolute;
    right: 0;
    top: -5px;
    color: #000;
    text-decoration: underline;


    &:hover, &:focus {
      color: #000;
    }
  }
}

#quick-search-wrapper {
  .dropdown-menu {

    .input-group-btn {
      text-align: right;
    }

    .form-group {
      margin-bottom: 0;

      input {
        border-radius: $border-radius-none;
        height: 36.3px;
        border: none;
        box-shadow: none;

        &:focus {
          box-shadow: none;
        }
      }

      svg {
        fill: $color-white;
      }

      button {
        background: $color-3;
        border-radius: $border-radius-none;
        color: #fff;
        display: flex;
        align-items: center;
        /*&:before {
          @extend .fa;
          content: $fa-var-search;
          padding-right: .25em;
        }*/

        &:hover, &:focus {
          background-color: #ececec;
          color: #000;

          &:before {
            color: $color-3;
          }
        }
      }
    }
  }
}

.header-tools {
  @media screen and (min-width: $grid-float-breakpoint) {
    display: none;
  }

  #login-form-wrapper {
    display: none;

    form {
      padding: $grid-gutter-width/2;
    }

    .dropdown-menu {
      left: auto;
      right: 0;
      width: 300px;
    }

    @media screen and (min-width: $grid-float-breakpoint) {
      float: left;
      display: block;
      margin-right: $grid-gutter-width;
    }
  }

  .global-search-area {
    @media screen and (min-width: $grid-float-breakpoint) {
      float: left;
      width: 180px;

      &.focus {
        width: 320px;
      }

      transition: width .2s ease-in-out;
    }

    form {
      @media screen and (max-width: $grid-float-breakpoint-max) {
        padding: $grid-gutter-width/2;
        box-shadow: inset 2px 2px 4px -2px rgba(0,0,0,.4);
      }
    }

    &.collapse {
      @media screen and (min-width: $grid-float-breakpoint) {
        display: block;
      }
    }

    .form-group {
      margin: 0;
    }
  }
}

.swosch-svg {
  width: 100%;
  display: block;


  path {
    fill: $color-3;
  }
}

.global-login-area {
  width: 100%;
  padding: $grid-gutter-width/2;
  background: #fff;
  left: 0;
}

.mobile-nav-functions {
  margin-top: 7px;
  margin: 0;
  position: relative;

  @media screen and (max-width: $screen-sm-max) {
    margin-left: -15px;
    margin-right: -15px;
    background-color: $color-nav-mobile-bg;

    .global-search form {
      padding: 0.5em;
      background: $color-9;

      svg {
        fill: $color-white;
        width: 27px;
        height: 27px;
      }

      .form-group {
        margin-bottom: 0;
      }
    }

    button {
      color: #fff;

      &:before {
        color: #fff;
      }
    }

    .search-toggle {
      float: right;
      /*&:before {
        content: $fa-var-search;
      }*/

      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        fill: $color-white;
        width: 27px;
        height: 27px;
      }
    }

    .navbar-nav {
      border-top: solid 1px;
      border-color: tint(#fff, 90%);
    }
  }

  .forgot-password {
    position: absolute;
    right: 0;
    top: -5px;
    color: #000;
    text-decoration: underline;
  }
}


.login-inner-wrapper {
  padding: 0 15px;

  &.MyFarm {
    background: linear-gradient(0deg, #F08A00 0%, #FABA00 100%);
  }

  &.MyPages {
    background: linear-gradient(0deg, #00a65c 0%, #8cc799 100%);
  }

  .nav {
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 10px;
    border: none;

    li {
      border: none;
      width: 50%;
      padding-left: 0;
      padding-right: 0;
      border-bottom: solid 1px #fff;

      a {
        border: none;
        text-align: left;
        margin: 0;
        border-radius: 0;
      }

      &.mypages {
        a {
          background: $color-sommarang;
          display: flex;
          align-items: center;
        }

        svg {
          fill: $color-black;
          width: 25px;
          height: 25px;
        }

        &.active {
          border-bottom: none;
        }
      }

      &.myfarm {
        border-right: solid 1px #fff;

        a {
          background: $color-yellow;
          display: flex;
          align-items: center;
        }

        svg {
          fill: $color-black;
          width: 25px;
          height: 25px;
        }

        &.active {
          border-bottom: none;
        }
      }
    }
  }

  .nav-tabs > li.active > a, .nav-tabs > li > a {
    border: none;
    color: #000;
    font-weight: bold;

    &:hover, &:focus, &:active {
      border: none;
    }
  }

  .form-group {
    position: relative;
  }

  .validation {
    padding: 15px;
    background: #fdeccd;
    margin-bottom: 15px;
    line-height: 1;
    font-size: 14px;
    position: relative;
    margin-top: 25px;

    .fa {
      position: absolute;
      top: 25px;
      color: Red;
      left: 10px;
      font-size: 20px;

      &.blue {
        color: blue;
      }
    }

    span {
      margin-left: 25px;
      display: inline-block;
      margin-bottom: 1em;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.login-header {
  font-size: 18px;
  margin-top: 0;
}

.password-input-wrapper {
  margin-bottom: 0;
}

.loginbutton-wrapper {
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 0;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
  border-top: solid 1px #fff;
  background: #fff;
  margin-top: 25px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;

  button {
    width: 100%;
  }

  .validation {
    padding-bottom: 5px;
  }
}

.myfarm-icon, .mypages-icon {
  width: 21px;
}

.btn-to-service {
  margin-bottom: 15px;
}

.head-for-print {

  .container-fluid {
  }

  .logo {
    z-index: 3;
    transition: all .2s ease-in-out;
    float: left;
    position: relative;
    left: 15px;
  }
}

// ----- Link list
.link-list {
  @include list-unstyled();
  padding: 0;
  margin: 0;

  a {
    padding: 0.33em 0.5em 0.5em 2.5em !important;
    display: block !important;
    position: relative;
  }


  &.double-link {
    & li {
      a {
        display: inline-block;
        position: relative;

        aside & {
          display: block;
        }

        &:last-child {
          padding-left: 0.5em;
          padding-top: 0.1em;
          padding-bottom: 0.1em;
          margin-bottom: 1em;

          aside & {
            padding-left: 2.3em;

            &:hover, &:focus {
              background: none;
            }
          }

          &:before {
            width: auto;
            padding: 0 0 0 0.3em;
            margin-right: 0;
            position: static;
            display: inline-block;
            content: "\00a0";
            border-left: solid 1px #fff;

            aside & {
              border-left: none;
            }
          }
        }
      }
    }
  }
}


.link-list,
.editor {
  a {

    &[href^="http://"],
    &[href^="https://"],
    &[href$=".pdf"],
    &[href$=".doc"],
    &[href$=".docx"],
    &[href$=".xls"],
    &[href$=".xlsx"],
    &[href$=".ppt"],
    &[href$=".pptx"],
    &[href$=".jpg"], &[href$=".jpeg"], &[href$=".png"], &[href$=".png"], &[href$=".gif"], &[href$=".tif"], &[href$=".tiff"], &[href$=".bmp"] {
      padding: 0.33em 0.5em 0.5em 2.5em;
      display: inline-block;
      position: relative;

      &,
      &:hover:before,
      &:focus:before {
        transition: all ease-in-out .08s;
      }

      &:before {
        @extend .fa;
        position: absolute;
        width: 25px;
        text-align: center;
        transition: color ease-in-out .15s;
        margin-right: 0.5em;
        color: $gray-darker;
      }
    }

    &[href^="/"] {
      &:before {
        content: "";
      }
    }

    &[href^="http://"],
    &[href^="https://"],
    &[href$=".pdf"],
    &[href$=".doc"],
    &[href$=".docx"],
    &[href$=".xls"],
    &[href$=".xlsx"],
    &[href$=".ppt"],
    &[href$=".pptx"],
    &[href$=".jpg"], &[href$=".jpeg"], &[href$=".png"], &[href$=".png"], &[href$=".gif"], &[href$=".tif"], &[href$=".tiff"], &[href$=".bmp"] {
      &:before {
        font-size: 19px;
        top: 0.30em;
        left: 0.5em;
      }
    }

    &[href^="http://"],
    &[href^="https://"] {
      &:before {
        content: $fa-var-external-link;
        font-size: 15px;
        top: 10px;
        left: 10px;
      }
    }

    &[href$=".pdf"] {
      &:before {
        content: $fa-var-file-pdf-o;
      }
    }

    &[href$=".doc"],
    &[href$=".docx"] {
      &:before {
        content: $fa-var-file-word-o;
      }
    }

    &[href$=".xls"],
    &[href$=".xlsx"] {
      &:before {
        content: $fa-var-file-excel-o;
      }
    }

    &[href$=".ppt"],
    &[href$=".pptx"] {
      &:before {
        content: $fa-var-file-powerpoint-o;
      }
    }

    &[href$=".jpg"], &[href$=".jpeg"], &[href$=".png"], &[href$=".png"], &[href$=".gif"], &[href$=".tif"], &[href$=".tiff"], &[href$=".bmp"] {
      &:before {
        content: $fa-var-file-picture-o;
      }
    }
  }
}

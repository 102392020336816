.block.contactcardpage {
  @include clearfix();
}
.contact-card {
  @include clearfix();
  background: #fff;
  padding: $grid-gutter-width/2;
 
  margin-top: 0; //$grid-gutter-width;
  margin-bottom: $grid-gutter-width;

  h1 {
    @media screen and (max-width: $screen-sm) {
      font-size: $font-size-h3;
    }

    padding: 0 0 $grid-gutter-width/2;
    margin-top: 0;
    border-bottom: solid 1px $border-color;
  }
}

/*When used as block*/
.block,
.modal-body {
  .contact-card {
    padding-top: 0;
    margin-bottom: 0;
    @include make-xs-column(12);

    .contact-card-header {
      margin-left: -15px;
      margin-right: -15px;
    }

    .contact-card-body {
      padding-top: 8px;

      .row {
        > div {
          @include make-xs-column(12);

          .modal & {
            @media screen and (min-width: $screen-sm) {
              @include make-sm-column(6);
              &:nth-child(2n+1) {
                clear: both;
              }
            }
          }
        }
      }
    }

    .modal-only {
      display: none;
    }

    h4 {
      font-size: $font-size-base;
      margin-bottom: 0;

      .modal & {
        font-size: $font-size-h4;
        margin-bottom: 1rem;
        padding-right: 30px;
      }

      a {
        &,
        &:hover,
        &:focus {
          color: $text-color;
        }
        &:hover,
        &:focus {
          text-decoration: underline;

          .fa-user {
            text-decoration: none;
          }
        }
      }
    }

    .fa-user {
      font-size: 40px;
      color: $color-1;
      transform: translateY(15%);
      width: 50px;
      padding: 0;
      text-align: center;
    }

    p {
      margin-bottom: 5px;
    }

    .contact-card-email {
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 100%;
    }

    .profile-pic {
      padding-top: 15px;
    }
  }

  @media screen and (max-width: $screen-lg) {
    //Don't show profilepicture on smaller screens
    .profile-pic {
      display: none;
    }
  }
}



.contact-card {
  .contact-card-header {
    position: relative;

    a {
      display: table;
      table-layout: fixed;
      width: 100%;
      padding: 10px 15px;

      > * {
        display: table-cell;
        vertical-align: middle;
        padding: 0 0 0 10px;
      }
    }
  }
  .contact-img {
    display: inline-block;
    position: relative;
    overflow: hidden;
    width: 50px;
    height: 50px;
    border-radius: 6px;

    img {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 0;
      max-width:50px;
    }

    .modal & {
      @media screen and (min-width: $screen-sm) {
        width: 80px;
        height: 80px;
        border-radius: 10px;

        img {
          max-width: 80px;
        }
      }
    }
  }
}


body.no-js .block .contact-card,
.modal .contact-card {
  .modal-only {
    display: block !important;
  }
}

#contactModal .modal-body {
  @include clearfix();

  .close {
    position: absolute;
    z-index: 2;
    right: $grid-gutter-width/2;
    top: $grid-gutter-width/2;
  }
}


.contact-card-hit {
  @include clearfix();

  .contact-img,
  .contact-img-placeholder {
    position: absolute;
    margin: 0;
    left: $grid-gutter-width/2;
    top: $grid-gutter-width/2;
    max-width: 80px;
    border-radius: 6px;

    @media screen and (max-width: $screen-xs-max) {
      left: 20px;
      max-width: 55px;
    }
  }

  .contact-img-placeholder {
    height: 100px;

    @media screen and (max-width: $screen-xs-max) {
      height: 69px;
    }
  }
}


.contact-card-img {
  border-radius: 6px;
  margin-bottom:1em;
}


.contact-img-placeholder {
  color: #ccc;
  background: #f3f3f3;
  position: relative;
  display: inline-block;
  width: 100%;
  height: 200px;
  text-align: center;
  max-width: 160px;

  @media screen and (max-width: $screen-xs-max) {
    height: 100px;
  }

  svg {
    fill: #ccc;
  }
}

.contact-form {
  position: absolute;
  right: 50%;
  top: 130px;
  width: 350px;
  max-width: 95vw;
  overflow: auto;
  opacity: 0;
  visibility: hidden;
  max-height: 0;
  background-color: $color-white;
  padding: 0 1.5em 0 1.5em;
  border-radius: 0.75em;
  z-index: 999;
  box-shadow: 0px 0px 35px 1px rgba(0,0,0,0.35);
  transform: translate( 50%, 0);
  transition: opacity 0.25s ease-in, visibility 0.25s ease-in;

  @media screen and (min-width:$screen-sm) {
    top: 160px;
  }

  @media screen and (min-width:$screen-md) {
    position: fixed;
    right: 3em;
    bottom: 4em;
    top: auto;
    transform: none;
  }

  * {
    opacity: 0;
    visibility: hidden;
  }

  &--shown {
    opacity: 1;
    visibility: visible;
    max-height: 1000px;

    * {
      opacity: 1;
      visibility: visible;
    }
  }

  &__button {
    position: fixed;
    bottom: 0.5em;
    right: 0.5em;
    background-color: transparent;
    border: none;
    width: 40px;
    height: 40px;
    padding: 0;
    z-index: 1000;
    transition: transform .3s ease-in;

    @media screen and (min-width:$screen-md) {
      bottom: 2em;
      right: 2em;
      width: 50px;
      height: 50px;
    }

    &:hover {
      transform: scale(1.08);
    }

    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      content: "";
      background-color: $color-orange;
      transform: translate( -50%, -50%);
      width: 100%;
      height: 100%;
      border-radius: 25px;
    }

    svg {
      height: 100%;
      width: 100%;
      fill: $color-white;
      position: relative;
      z-index: 1;
    }
  }

  &__text {
    margin-bottom: 2em;
  }

  .Form__Title,
  .Form__Element__Caption {
    font-weight: 400;
  }

  .FormSubmitButton {
    background-color: $color-orange;
    color: $color-white;

    &:hover,
    &:focus-visible {
      color: $color-black;
      background-color: $hover-background-color;
    }
  }
}

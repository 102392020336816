/* CSS Document */



.calendar-day { 
    position: relative;
    display: table;
    width: 100%;
    /*border-top: solid 1px $border-color;*/
    &:first-child {
        border-top: none;
    }

    .media-left {
        padding: 0;
    }

    .calendar-list {
        vertical-align: middle;
        padding: $grid-gutter-width/2;

        ul {
            @include list-unstyled();
            padding: 0;
            margin: 0;  

            li {
                margin-bottom: $grid-gutter-width/2;

                &:last-child {
                    margin-bottom: 0;
                }

                a { 
                    display: block;
                    /*padding: 0 $grid-gutter-width/2;*/
                    p {
                        color: $gray;
                        margin-top: $grid-gutter-width/3;
                        font-size: $font-size-small;
                    }

                    &:hover,
                    &:focus {
                        text-decoration: none;

                        h3 {
                            text-decoration: underline;
                        }
                    }
                }

                h3 {
                    margin: 0;

                    @media screen and (max-width: $screen-xs-max) {
                        font-size: $font-size-h3*.75;
                    }
                }

                p {
                    margin: 0;
                }
            }
        }
    }
}


.icon-category,
.icon-location,
.icon-date,
.icon-time {
    .calendar-item-meta & {
        display: inline-block;

        &:before {
            @extend .fa;
            padding-right: 5px;
        }
    }

    &.icon-category:before {
        content: $fa-var-tag;
    }

    &.icon-location:before {
        content: $fa-var-map-marker;
    }

    &.icon-date:before {
        content: $fa-var-calendar;
    }

    &.icon-time:before {
        content: $fa-var-clock-o;
    }

    .calendar-list & {
        margin-left: $grid-gutter-width/3;

        &:first-child {
            margin-left: 0;
        }
    }

    dl & {
        position: relative;
        padding-left: 20px;

        &:before {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}


.event-time {
  color: #a7a7a7;
  font-size: 90%;
  margin-bottom: 7px;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-weight: 200;
  display: flex;

  svg {
      fill: currentColor;
      width: 20px;
      height: 20px;
  }

  .event-last-application {
    color: #a7a7a7;
    font-weight: 200;
  }
}

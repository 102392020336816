$grid-bottom-margin: $grid-gutter-width !default;
$block-border-radius: $border-radius-small !default;
$block-bg: #fff !default;
$block-bg-hover: $hover-background-color !default; //todo create variable for the hover bg-color
$block-padding: $grid-gutter-width;

@mixin block-radius-mixin($block-rounded: $block-border-radius) {
    border-radius: $block-rounded;
    overflow: hidden;
}

@mixin block-bg-mixin($block-bg-color: $block-bg) {
    background: $block-bg-color;
}

@mixin block-bg-hover-mixin($block-bg-color: $block-bg-hover) { //background color on hover/focus event adds a line at bottom of block
    background: $block-bg-hover;
    position: relative;


    &:after {
        content: " ";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 2px;
        background: #e0e0e0;
    }
}

@mixin animate-bg-mixin() {
    transition: background .1s ease;
}

@mixin block-heading-mixin() {
    text-align: center;
    display: block;
    font-family: $headings-font-family;
    font-size: 30px;

    span {
        display: inline-block;
        position: relative;
        padding-bottom: 11px;

        &:before {
            content: $blank-content;
            position: absolute;
            left: 10px;
            right: 10px;
            bottom: 0;
            border-bottom: solid 4px #989a9c;
        }
    }

    @media screen and (max-width: $screen-xs-max) {
        font-size: 23px;
    }
}

// Blocks
// --------------------------------------------------
.block {
    margin-bottom: $grid-bottom-margin;

    @media screen and (min-width: $screen-sm) {
        .startpage .wide-wrapper.wide-bg-01 .row:last-child & {
            margin-bottom: 0;
        }
    }

    h2{
        @media screen and (max-width: $screen-xs-max) {
            font-size: $font-size-h2*.70;
        }
    }

    &.col-sm-12 {
        clear: both;
    }

    .block-content{
        & > h2{
            margin-top:0;
        }
    }
}

.block-heading {
    /*border:solid 1px red;*/
}


.block-footer {
    a.btn {
       margin:0;
    }
}

.contact-cards {
    .contact {
        clear: both;
    }

    .block > & {
        padding-left: 15px;
        padding-right: 15px;
    }
}

// vcard standard - http://microformats.org/wiki/hcard
.vcard {
    @include clearfix();
    border-bottom: solid 1px $gray-lighter;
    padding: 15px 0;

    .contact:last-child & {
        border-bottom: none;
    }

    .fn {
        font-family: $headings-font-family;
        font-size: $font-size-h4;
    }

    > *:not(.fn) {
        font-size: 13px;
        line-height: 1.7em;
    }

    > span {
        display: block;
    }

    p {
        margin: 0;
    }

    .profile-link {
        float: right;
        max-width: 160px;
        max-height: 140px;

        img {
            @include img-responsive();
        }

        span {
            display: block;
            text-align: center;
            padding-top: 5px;
        }

        @media screen and (max-width: $screen-xs-max) {
            max-width: 100px;
            max-height: 80px;
        }
    }
    //.note { font-style:italic}
}


.embed-responsive {
    clear: both;
}

//user meta list
.user {
    @include clearfix();

    img {
        float: left;
        margin-right: 1em;
    }

    .user-wrap {
        border-top: solid 2px $border-color;
        border-bottom: solid 2px $border-color;
        padding: $grid-gutter-width 0;
        margin: $grid-gutter-width/2 0 $grid-gutter-width;
    }

    dl {
        @include clearfix();
        @extend .dl-horizontal;
        float: left;
        margin-bottom: 0;

        dt,
        dl {
            margin-bottom: .5em;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        dt {
            text-align: left;
            width: 110px;
        }

        dd {
            margin-left: 110px;
        }

        @media screen and (min-width: $screen-xs-min) and (max-width: $screen-sm-max) {
            dt {
                text-align: left;
                width: auto;
                margin-bottom: 0;
            }

            dd {
                margin-left: inherit;
                margin-bottom: 1em;
            }
        }
    }

    .block {
        clear: both;
        margin-bottom: 0;
        padding-top: 1em;
        @include clearfix();
    }
}

//calendar meta list
.calendar-item-meta {
    @extend .user;

    .calendar-item-wrap {
        @extend .user-wrap
    }

    .calendar-day {
        float: left;
        margin-right: 1em;
        display: inline-block;
        width: auto;

        .media-left {
            width: 5em;
            padding: 0;
            border: none;
        }
    }
}



$alert-bg:             shade($color-2, 20%) !default;
$alert-color:          #fff !default;
$alert-link-color:     $alert-color !default;        


.page-alert {
  font-size: $font-size-small;
  color: $alert-color;
  background: $alert-bg;
  padding: $grid-gutter-width/2;
  
  p {
    display: inline;
    margin: 0;
  }
  
  a {
    display: inline-block;
    color: $alert-link-color;
    
    &:before {
      @extend .fa;
      content: $fa-var-angle-right;
      padding-right: 5px;
    }
  }
  
  
  .cookie-info {
    @media screen and (min-width: $screen-sm) {
      @include make-sm-column(9);
    }
  }
  
  
  form {
    @media screen and (min-width: $screen-sm) {
      @include make-sm-column(3);
      
      text-align: right;
    }
  }
}

#cookieaccept {
  @extend .btn;
  @extend .btn-default;
}


.alert-messages {
  background: $content-bg;
}
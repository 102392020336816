// Main article page
// --------------------------------------------------

.show-for-print {
  display: none;
}

@media print {
  @page {
    size: 330mm 427mm;
    margin: 0 14mm;
  }

  .container {
    width: 1170px;
  }

  .head-for-print {
    margin-bottom: 25px;
  }

  .hide-for-print {
    display: none;
  }

  .show-for-print {
    display: block;
    padding: 0;
  }

  .headlinePrint {
    margin-top: 25px !important;
  }

  .logo {
    a {
      display: inline-block !important;

      svg {
        max-width: 100%;
        height: 70px;
        margin-top: 30px;
      }

      img {
        max-width: 257px !important;
        max-height: 100px !important;
      }
    }
  }

  // HEADER AND FOOTER SETTINGS
  .footer-for-print,
  .head-for-print {
    z-index: 10;
    display: block !important;
    margin-top: 35px !important;
    padding: 0px;

    .imageblock {
      height: auto !important;
      max-height: !important;
    }

    .container-fluid {
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  .footer-for-print {
    border-radius: 10px;
  }

  .contact-img {
    overflow: initial !important;
    padding: 0px !important;
    position: relative !important;
    height: auto !important;

    img {
      display: inline-block !important;
      position: relative !important;
      transform: initial !important;
      left: 0px !important;
    }

    span:not(.contact-img) {
      display: inline !important;
    }
  }

  .contact-card .contact-img img {
    transform: translateX(-10%);
  }

  .img-responsive {
    display: block;
    height: auto;
  }

  .col-sm-8 {
    width: 100%;
  }

  @supports (-ms-ime-align: auto) {
    .hej,
    .head-for-print {
      display: block !important;
      margin-top: 35px !important;
      padding: 0px;
    }
  }

  // ARTIKELSIDA AKA STANDRARDPAGE
  .articlePrint {
    #main-footer, #nav, .global-nav, .btn, .epi-quickNavigator, .customformcontainerblock, .article-footer, .page-alert, .alertblock, .subnavigation-area, .share-bar {
      display: none !important;
    }

    .logo {
      a {
        display: inline-block !important;

        svg {
          max-width: 100%;
          height: 50px;
          margin-top: 30px;
        }

        img {
          max-width: 257px !important;
          max-height: 100px !important;
        }
      }
    }

    a[href]:after {
      content: none !important;
    }
    // REMOVED FOR PRINT AS STANDARD
    .breadcrumb,
    .formcontainerblock,
    .linkcollectionblock,
    .article-footer,
    .editor,
    .campaign-content-area,
    .related-info,
    .mainContentArea,
    .topContentArea {
      display: none !important;
    }

    .editor {
      a {
        display: none !important;
      }
    }
    // REACTIVATE IF PRINT IS CLICKED IN ADMIN UI
    .breadcrumb.print,
    .formcontainerblock.print,
    .linkcollectionblock.print,
    .article-footer.print,
    .imagefile.print,
    .editor.print,
    .mainContentArea.print,
    .topContentArea.print,
    .editor.printMainBody,
    .campaign-content-area.print,
    .editorialblock.print,
    .gdprblock.print,
    .related-info.print,
    .topMainBodyText {
      display: initial !important;
    }

    .mainContentArea.print {
      .row {
        margin: 28px !important;
      }
    }

    .related-info.print {
      .row, h3 {
        margin: 28px !important;
      }
    }
  }


  // CALENDARPAGE
  .calendarPrint {
    #main-footer, #nav, .container-fluid, .global-nav, .btn, .epi-quickNavigator, .article-footer, .breadcrumb, .breadcrumb * .related-info, .formcontainerblock, .customformcontainerblock, .page-alert, a, .alertblock, .deadline-has-passed-text, .subnavigation-area {
      display: none !important;
    }

    .logo {
      a {
        display: inline-block !important;

        svg {
          max-width: 100%;
          height: 50px;
          margin-top: 30px;
        }

        img {
          max-width: 257px !important;
          max-height: 100px !important;
        }
      }
    }


    ol.breadcrumb {
      display: none !important;
    }

    a[href]:after {
      content: none !important;
    }
    // REMOVED FOR PRINT AS STANDARD
    .linkcollectionblock,
    .editor,
    .campaign-content-area,
    .mainContentArea,
    .topContentArea,
    .content-area {
      display: initial !important;
    }

    .event-side-info {
      display: none;
    }

    .specific-time-text__date {
      display: inline;
    }

    .application-deadline {
      display: block;
    }

    .main-article {
      .editor {
        a {
          display: initial !important;
        }
      }

      .calendarForm {
        display: none !important;
      }
    }
  }

  // COOKIEBOT
  #CookiebotWidget {
    display: none;
  }

  .global-nav,
  #epi-quickNavigator,
  .mobile-nav-functions,
  .breadcrumb,
  .pagination,
  #search-wrapper,
  .main-footer,
  .contactsearchpage .lead {
    display: none !important;
  }

  .contact-card-hit {
    padding: 0 !important;
    break-inside: avoid;

    a[href]:after {
      content: '';
    }
  }

  .contact-card-header h2 {
    border-bottom: 1px solid $color-silver;
    padding-bottom: 0.25em;
    margin-bottom: 0.5em;
  }

  .contactusprint {
    background-color: rgba(240, 138, 0, 0.1) !important;
    padding: 2em 1.5em;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    break-inside: avoid;

    .col-sm-8 {
      width: 66.6666666667%;
    }

    h2 {
      margin-bottom: 1em;
    }
  }

  .search-result article {
    margin-bottom: 0;
  }

  .search-result article .contact-card-hit img {
    margin-bottom: 0;
  }

  // Ugly bug in Chronium from Chrome 119 that ignored @page in top of this file.
  .contact-card-hit {
    .col-xs-4.col-xs-push-8.col-sm-push-0.col-sm-3.col-md-2 {
      width: 16.6666666667%;
      float: left;
      left: auto;
    }

    .col-xs-8.col-xs-pull-4.col-sm-pull-0.col-sm-9 {
      right: auto;
      width: 75%;
      float: left;

      .col-xs-12.col-sm-6.col-md-4 {
        width: 33.3333333333%;
        float: left;
      }
    }
  }
}

// Navbar
// --------------------------------------------------
.navbar.navbar-default {
  border-radius: 0;
  margin: 0;
  border: none;
  background: $color-orange;
  background: linear-gradient(0deg, $gradient-from 0%, $gradient-to 100%);
  padding: 20px;
  padding-bottom: 13px;

  .husdjur &, .notkott & {
    border-top: 15px solid $color-1;
    padding: 0;
  }

  @media screen and (max-width: $grid-float-breakpoint-max) {
    min-height: 1px;
    padding: 0;

    .husdjur &, .notkott & {
      border-top: none;
    }
  }

  @media screen and (min-width: $grid-float-breakpoint) {
    bottom: 0;
    left: 0;
    right: 0;
  }

  .navbar-collapse {
    @media screen and (min-width: $grid-float-breakpoint) {
      float: none;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      padding: 0;
      margin: 0;
      text-align: center;
    }
  }

  .nav.navbar-nav {
    @media screen and (min-width: $grid-float-breakpoint) {
      float: none;
      display: inline-block;
      margin: 0 auto;
    }

    > li {

      & > a,
      & > span {
        color: $menu-color;

        @media screen and (min-width: $screen-md) {
          font-size: 17px;
          padding: 0 15px;
          display: block;
        }

        @media screen and (min-width: $screen-lg) {
          padding: 0 20px;
        }
      }

      & > a {
        &:hover,
        &:focus {
          color: $menu-active-color;
        }
      }

      &.open,
      &.active {
        > a {
          @media screen and (min-width:$screen-md) {
            background: transparent;
            color: $menu-active-color;

            &:hover,
            &:focus {
              color: $menu-active-color;
            }
          }
        }
      }
    }
  }
}

﻿
.pagelistblock, .newslistblock {
  .block-content {
    > h2.header {
      margin-top: 22px;
    }
  }

  h2 {
    &:first-child {
      /*margin-top: 20px;*/
    }

    @media screen and (max-width: $screen-xs-max) {
      padding-bottom: 0;
      margin-bottom: 11px;
      /*font-size: $font-size-h3*.85;*/
      //font-weight: normal;
      border-bottom: 0;
    }

    @media screen and (max-width: $screen-xs) {
      border-top: 0;
    }

    &.small-text-link {
      font-size: 24px;

      i {
        font-size: 16px;
      }
    }

    &.large-text-link {
      i {
        font-size: 20px;
      }
    }
  }

  a {
    color: $text-color;
  }

  .btn-primary {
    color: #fff;
  }

  article {
    @include block-bg-mixin();
    //@include block-radius-mixin();
    margin: 10px 0;

    a {
      display: block;
      color: $text-color;
      padding: 20px;
      @include animate-bg-mixin();

      h3 {
        margin-top: 0;
        /*color: $link-color;*/
        /*font-size: $font-size-h4;*/
        @media screen and (max-width: $screen-xs-max) {
          font-size: $font-size-h3*.70;
        }
      }

      p:last-child {
        margin: 0;
      }

      &:hover,
      &:focus {
        text-decoration: none;
        color: $text-color;
        @include block-bg-hover-mixin();
      }
    }
  }

  .date {
    @media screen and (max-width: $screen-xs) {
      display: none;
    }
  }

  .hide-introduction {
    article {
      a {
        &:hover,
        &:focus {
          text-decoration: none;
          color: $text-color;
          background: #fff;

          h3 {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

﻿.buttonblock {
  @extend .teaserblock;

  .btn-block {
    border-top: solid 5px $color-1;

    @media screen and (max-width: $screen-xs){
        border-top:0;
    }

    .text {
      @media screen and (min-width: $screen-sm) {
        @include min-height(170px);
      }
      @media screen and (min-width: $screen-md) {
        @include min-height(200px);
      }
      @media screen and (min-width: $screen-lg) {
        @include min-height(210px);
      }

      p:last-child {
        margin-bottom: 0;
      }
    }

    > a {
      margin: 0 $grid-gutter-width/2 $grid-gutter-width/2;
    }
  }

  &.col-sm-3 {
    .btn-block {
      .text {
        padding: $grid-gutter-width/2;
      }
      > a {
        margin: 0 $grid-gutter-width/2 $grid-gutter-width/2;
      }
    }
    @media screen and (min-width: $screen-sm) and (max-width: $screen-sm-max) {
      @include make-sm-column(6);
    }
  }

  h2{
      font-size:$font-size-h4;
  }
}

﻿.image-container {
    position: relative;
    margin-bottom:15px;

    .background-image {
        width: 100%;
    }

    @media screen and (max-width: $screen-xs-max) {
        display: none;
    }

    .info-point {
        position: absolute;
        font-size: 1.2rem;
    }

    .fa{
        position:absolute;
    }
}

.non-image-container {
    /*margin-top:-30px;*/
    margin-bottom: 15px;
    display: none;

    @media screen and (max-width: $screen-xs-max) {
        display: block;
    }

    .info-point {
        margin-bottom: 10px;
        display: block;
        position: relative;
        font-size: 1rem;
    }
}


.info-point {
    border-radius: 28px;
    background: rgba(1, 1, 1, 0.5);
    color: #fff;
    border: solid 1px transparent;

    &:hover, &:focus, &:active {
        text-decoration: none;
        border: solid 1px #cc7500;
        color: #fff;

        .fa {
            background: #cc7500;
            color: #fff;
        }
    }

    .fa {
        /*position: absolute;*/
        top: 0;
        background: #f08a00;
        border-radius: 50%;
        padding: 8px;
    }

    &.arrow-left {
        padding: 5px 10px 5px 28px;

        .fa {
            left: -5px;
        }
    }

    &.arrow-right {
        padding: 5px 28px 5px 10px;

        .fa {
            right: -5px;
        }
    }
    /*@media screen and (max-width: $screen-xs-max) {
        border:solid 10px red;
    }*/
}

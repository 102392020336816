// ASIDE NAVIGATION
// --------------------------------------------------

@mixin nav-vertical-node() {
  > li {
    @media screen and (max-width: $grid-float-breakpoint-max) {
      border-top: solid 1px $mobile-nav-border;

      &:first-child {
        border-top: none;
      }

      > a {
        color: $mobile-nav-color;
        background: $mobile-nav-bg;
      }

      &.active {
        > a {
          color: $mobile-nav-active-color;
          background: $mobile-nav-active-bg;
        }
      }
    }
  }

  ul {
    border: none;

    &,
    & li {
      list-style: none;
    }
  }
}


@mixin nav-vertical-sub() {
  @include clearfix();

  > ul {
    clear: both;
    padding: 0;
    margin: 0 0 $grid-gutter-width 0;

    > li {
      > a,
      > span:not(.nav-collapse-btn) {
        color: $mobile-nav-color-2;
        background: $mobile-nav-bg-2;
      }

      > a:hover,
      > a:focus {
        background: shade($mobile-nav-bg-2, 5%);
      }
    }

    > li.locked {
      > a:before,
      > span:not(.nav-collapse-btn):before {
        position: static;
      }
    }

    &,
    & ul {
      border-left: solid 6px $mobile-nav-bg;
    }
    border-color: tint($mobile-nav-bg, 20%);
    ul {
      border-color: tint($mobile-nav-bg, 40%);
      ul {
        border-color: tint($mobile-nav-bg, 60%);
        ul {
          border-color: tint($mobile-nav-bg, 80%);
          ul {
            border-color: tint($mobile-nav-bg, 95%);
          }
        }
      }
    }
  }
}


@mixin nav-vertical-sub-2() {
  @include clearfix();

  &,
  ul {
    padding: 0;
    margin: 0;
  }

  a,
  span:not(.nav-collapse-btn) {
    position: relative;
    display: block;
    padding: 9px $grid-gutter-width*1.5 10px $grid-gutter-width;
    border-radius: 0;
    border-top: solid 1px $mobile-nav-border-2;
    color: $mobile-nav-color-3;
    background: $mobile-nav-bg-3;

    &,
    &:hover,
    &:focus {
      transition: background ease-in-out .08s;
      text-decoration: none;
      outline: 0;
    }
  }

  li {
    position: relative;

    &,
    & + li {
      margin: 0;
    }


    li {
      &,
      .nav-sub-collapse {
        background: $nav-vert-open-bg;
      }

      a,
      span:not(.nav-collapse-btn) {
        color: $nav-vert-open-color;
        border-color: tint($border-color, 50%);
      }

      a:hover,
      a:focus {
        color: $nav-vert-open-color;
        background: shade($nav-vert-open-bg, 10%);
      }
    }

    &.locked {
      > a,
      > span:not(.nav-collapse-btn) {
        &:before {
          @extend .fa;
          content: $fa-var-lock;
          color: $text-color;
          position: absolute;
          top: 13px;
          left: 12px;
        }
      }
    }

    &.loading {
      display: none;
    }
    // ---------- Tree structure
    li {
      a,
      span:not(.nav-collapse-btn) {
        padding-left: $grid-gutter-width*1.5;
      }

      li {
        a,
        span:not(.nav-collapse-btn) {
          padding-left: $grid-gutter-width*2;
        }

        li {
          a,
          span:not(.nav-collapse-btn) {
            padding-left: $grid-gutter-width*2.5;
          }

          li {
            a,
            span:not(.nav-collapse-btn) {
              padding-left: $grid-gutter-width*3;
            }

            li {
              a,
              span:not(.nav-collapse-btn) {
                padding-left: $grid-gutter-width*3.5;
              }
            }
          }
        }
      }
    }
    // ---------- STATES active, has-children, open
    &.active {
      > a,
      > span:not(.nav-collapse-btn) {
        &,
        &:hover,
        &:focus {
          color: $nav-vert-active-color !important;
          background: $nav-vert-active-bg !important;
        }
      }
    }

    &.has-children.nav-open-active > ul {
      position: relative;

      &:before {
        content: $blank-content;
        position: absolute;
        z-index: 33;
        width: 5px;
        top: 0;
        bottom: 0;
        margin: 1px 0 0;
        background: yellow;
      }
    }
  }

  .collapsing {
    transition: 0s;
  }
}


@mixin nav-collapse-btn() {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 40px;
  height: 100%;
  text-align: center;
  padding: 0;
  margin: 0;

  color: $color-9-text !important;
  background: $color-nav-collapse-bg-btn !important;

  &:hover,
  &:focus {
    cursor: pointer;
    color: $mobile-nav-color !important;
    background: shade($mobile-nav-bg, 10%) !important;
  }

  &:before {
    line-height: 45px !important;
  }

  &:before,
  &[aria-expanded="true"]:before {
    @extend .fa;
    content: $fa-var-minus;
  }

  &.collapsed:before,
  &[aria-expanded="false"]:before {
    @extend .fa;
    content: $fa-var-plus;
  }

  &.loading:before {
    @extend .fa-spin;
    content: $fa-var-circle-o-notch;
  }

  #header & {
    @media screen and (min-width: $grid-float-breakpoint) {
      display: none !important;
    }
  }
}


.navigation-vertical {

  .nav-collapse-btn {
    @include nav-collapse-btn();
  }
  #mobile-nav > li > .nav-collapse-btn {
    width: 100%;
    height: 100%;
    background: transparent !important;

    &:before {
      position: absolute;
      width: 40px;
      right: 0;
      //right: 30px;
      color: $color-nav-collapse-btn;
    }
    &:after {
      content: $blank-content;
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;

    }
  }



  #header & {
    border: none;
    box-shadow: none;

    @media screen and (max-width: $grid-float-breakpoint-max) {
      padding: 0;
      overflow-y: hidden;
    }

    .nav-collapse-btn {
      @media screen and (min-width: $grid-float-breakpoint) {
        display: none !important;
      }
    }

    > div {
      @media screen and (max-width: $grid-float-breakpoint-max) {
        margin: 0;
        padding: 0;
      }
    }

    > div > ul {
      @include nav-vertical-node();

      @media screen and (max-width: $grid-float-breakpoint-max) {
        margin: 0;
      }
    }

    .mobile-nav {
      @include nav-vertical-sub();

      > ul {
        @include nav-vertical-sub-2();
      }

      @media screen and (min-width: $grid-float-breakpoint) {
        display: none !important;
      }
    }
  }


  // NO SUB NAV IN THIS PROJECT, only used in mobile view.
  .sub-nav-column & {
    margin: 0 !important;
    @include nav-vertical-sub();

    > ul {
      @include nav-vertical-sub-2();
    }
  }
}



// NO SUB NAV IN THIS PROJECT, only used in mobile view.
.sub-nav-column {
  > nav {
    @media screen and (max-width: $grid-float-breakpoint-max) {
      display: none !important;
    }
  }
}

#search-toggle-button {
    @media screen and (min-width: $screen-sm) {
        display: none;
    }
}
 
@mixin mobile-toggle-btn() {
    @extend .btn;
    @extend .btn-default;
    float: none;
    padding: 0 $grid-gutter-width/2;
    margin: 0;
    border-radius: 0;
    border: none;
    line-height: $mobile-nav-height;
    color: $mobile-toggle-btn-color;
    background: $mobile-toggle-btn-bg;

    &:hover,
    &:focus,
    &:active,
    &[aria-expanded="true"] {
        color: $mobile-toggle-btn-color-active !important;
        background: $mobile-toggle-btn-bg-active !important;

        &:before {
            color: $mobile-toggle-btn-color-active;
        } 
    }

    &:before {
        @extend .fa;
        padding-right: 5px;
        font-size: 1.2em;
        color: $mobile-toggle-btn-icon;
    }

    @media screen and (min-width: $grid-float-breakpoint) {
        display: none;
    }
}

@mixin header-toggle-btn() {
    @extend .btn;
    @extend .btn-primary;

    &:hover,
    &:focus,
    &:active {
    }

    &:before {
        @extend .fa;
        padding-right: 5px;
        font-size: 1.2em;
        color: #fff;
    }

    @media screen and (max-width: $screen-sm-max) {
        display: none;
    }

    @media screen and (min-width: $screen-md) {
        float: left;
    }
}

.navbar-header {
    @media screen and (max-width: $grid-float-breakpoint-max) {
        display: table;
        table-layout: fixed;
        width: 100%;
        border-bottom: solid 1px $border-color;
        margin-bottom: $grid-gutter-width/2;
    }

    button {
        text-align: center;

        @media screen and (max-width: $grid-float-breakpoint-max) {
            display: table-cell;
            width: 33.33333%;
        }
    }
}

.navbar-toggle,
.search-toggle,
.login-toggle {
    @include mobile-toggle-btn;
}

.navbar-toggle {
    border-right: solid 1px $border-color;

    &:before {
        content: $fa-var-bars;
    }
}

.login-toggle {
  border-left: solid 1px $border-color;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    fill: #FFF;
    width: 27px;
    height: 27px;
  }
}

/*.global-buttons {
    float: right;

    .search-toggle,
    .login-toggle {
        @include header-toggle-btn;
    }

    .search-toggle:before {
        content: $fa-var-search;
    }

    .login-toggle {
        content: $fa-var-lock;
    }
}*/




.btn {
    color: black;
    border-color: transparent;

    &:hover, &:active, &:focus {
        background-color: $hover-background-color;
        outline: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        border-color: transparent;
        color: black;
    }

    &:active:focus {
        outline: none;
        background-color: $hover-background-color;
        border-color: transparent;
        color: black;
    }
}

.button-wide {
    padding: 10px 40px;
}

.btn-cta {
    @extend .button-wide;
}

.btn-third {
    background: #fff;
}

.btn-secondary {
    background-color: $color-button-secondary-bg;
    color: $color-button-secondary-text;
}

.btn-primary {
    background-color: $color-button-primary-bg;
    color: $color-button-primary-text;
}

.load-more-hits {
    @extend .btn;
    @extend .btn-default;
}

.btn-filters {
    padding-left: 0;
    padding-right: 0;

    &::before {
        @extend .fa;
        content: $fa-var-plus-circle;
        padding-right: 5px;
        color: $gray-light;
    }

    &[aria-expanded=true]::before {
        content: $fa-var-minus-circle;
    }

    &:hover,
    &:focus {
        background: transparent;

        &::before {
            color: $color-3;
        }
    }
}



.button-color-1 {
    background-color: $color-1;
    color: #fff;
}

.button-color-2 {
    background-color: $color-2;
    color: #fff; 
}

.button-color-3 {
    background-color: $color-3;
    color:#fff;
}

.button-color-4 {
    color: $color-white;
    background-color: $color-4;
}

.button-color-5 {
    background-color: $color-5;
    color: #fff;
}

.button-color-6 {
    background-color: $color-6;
    color: #fff;
}

.button-color-7 {
    background-color: $color-7;
    color: #fff;
}

.button-color-8 {
    background-color: $color-8;
    color: #fff;
}

.button-color-9 {
    background-color: $color-9;
}

.button-color-10 {
    background-color: $color-10;
}

.button-color-11 {
    background-color: $color-11;
    color: #fff;
}

.button-color-12 {
    background-color: $color-12;
    color: #fff;
}

.button-color-13 {
    background-color: $color-13;
    color: #fff;
}

.button-color-14 {
    background-color: $color-14;    
}

.button-color-15 {
    background-color: $color-15;
}

.btn-multiselect {
  width: 100%;
  text-align: right;
  background: #fff;
  border: solid 1px #cccccc;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &:hover, &:focus {
    background: #fff;
    border: solid 1px gray;
  }

  svg {
    width: 18px;
    height: 18px;
  }
}

.btn-search, .btn-clear-search {
    padding: 10px 30px;
}

.btnLocation, .btnActivity {
  padding: 2px 10px;
  margin-right: 10px;
  margin-bottom: 7px;

  &:after {
    margin-left: 2px;
    font-family: FontAwesome;
    content: $fa-var-times;
  }
}

#selectedLocations{
    margin-bottom:15px;
}

#selectedActivities, #selectedLocations{
  button[id=""]{
    display:none;
  }
}

@media screen and (max-width: 600px) {
  .btnLocation, .btnActivity {
    display: none;
  }
}

$color-white: #FFF;
$color-black: #000;

// Huvudfärg
$color-orange: #F08A00; // Huvudfärg Orange

//Komplementfärger
$color-yellow: #FABA00; // Solros
$color-tegel: #800303;  // Tegel

// Accentfärger
$color-skog: #006633; // Skog
$color-gras: #00A65C; // Gräs
$color-sommarang: #8CC799; // sommaräng

$color-mossa: #478075; // Mossa
$color-vind: #66BFB5; // Vind
$color-havsbris: #A3D6D4; // Havsbris

$color-hav: #00404D; // Hav
$color-horisont: #006B85; // Horisont
$color-himmel: #4F8FA8; // Himmel

$color-midnatt: #032933; // Midnatt
$color-marin: #054257; // Marin
$color-lagun: #0A5763; // Lagun

// Gråskala
$color-berg: #575757; // Berg
$color-betong: #878787; // Betong
$color-silver: #DADADA; // Silver
$color-kalk: #EDEDED; // Kalk

$color-gray: #666;

$color-1:                     $color-skog !default; // #60840B !default;
$color-2:                     #C93B0E !default;
$color-3:                     $color-orange !default; // #F08A00 !default;
$color-4:                     $color-skog !default; // #ABDB6C !default;
$color-5:                     #739E0E !default;
$color-6:                     #2B5700 !default;
$color-7:                     #296CDB !default;
$color-8:                     #DA7656 !default;
$color-9:                     #F5AD4C !default;
$color-10:                    #C5E698 !default;
$color-11:                    #9DBB56 !default;
$color-12:                    #6B8A4C !default;
$color-13:                    #6998E6 !default;
$color-14:                    #E9E8E8 !default;
$color-15:                    #FBFBFB !default;
$color-16:                    #fceed9 !default;
$color-17:                    #e4e4e4 !default;
$color-18:                    #f8f8f8 !default;
$color-19:                  #1a4893 !default; 
$hover-background-color: #ececec !default;
  
$color-1-text:                #fff !default;
$color-2-text:                #fff !default;
$color-3-text:                #fff !default;
$color-4-text:                #fff !default;
$color-5-text:                #fff !default;
$color-6-text:                #fff !default;
$color-7-text:                #fff !default;
$color-8-text:                #fff !default;
$color-9-text:                #fff !default;
$color-10-text:               #fff !default;
$color-11-text:               #fff !default;
$color-12-text:               #fff !default;
$color-13-text:               #fff !default;
$color-14-text:               $color-1 !default;
$color-15-text:               $color-1 !default;

$gradient-from: $color-orange;
$gradient-to: $color-yellow;
$gradient-text: $color-tegel;

$color-button-primary-bg:       #F08A00 !default;
$color-button-primary-text:    $color-white;
$color-button-secondary-bg:    $color-gras;
$color-button-secondary-text:  $color-white;

//Mega menu variables
$mega-menu-bg: $color-16 !default;
$link-hover-color: $color-3!default;

// Mobile menu
$color-nav-mobile-bg: $color-orange;
$color-nav-collapse-btn: $color-white;
$color-nav-collapse-bg-btn: $color-orange;


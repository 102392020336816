$global-nav-bg: #fff !default;
$global-nav-color: #000 !default;


.global-nav {
  @include clearfix();
  background: $global-nav-bg;
  float: right;

  @media screen and (max-width: $screen-md) {
    display: none;
  }

  @media screen and (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    text-align: right;
    position: relative;
    margin-bottom: 1em;


    li.bordered:not(:last-child) {
      right: 7.3em;
    }

    li.bordered {
      position: absolute;
      right: 0;
      bottom: -10px;
    }
  }

  ul {
    @extend .list-inline;
    display: flex;
    align-items: center;
    font-weight: normal;

    @media screen and (max-width: $screen-xs-max) {
      display: table;
      table-layout: fixed;
      width: 100%;
    }

    li {
      @media screen and (max-width: $screen-xs-max) {
        display: table-cell;
      }

      .global-nav-icon {
        display: flex;
        align-items: center;
      }

      svg {
        width: 25px;
        height: 25px;
        fill: $color-gray;
        margin-right: 5px;
      }

      &:hover {
        svg {
          fill: $color-orange;
        }
      }

      &.bordered {
        .dropdown > button {
          transition: all ease-in-out .1s;
          background: #fff;
          border: 2px solid $color-orange;
          padding: 8px 13px;
          border-radius: 6px;
          color: $color-orange;
          display: flex;
          align-items: center;

          .fa,
          svg {
            margin-right: 5px;
            color: $color-orange;
            fill: $color-orange;
          }

          &:hover, &:active {
            text-decoration: none;
            color: $color-tegel;
            border-color: $color-tegel;

            svg {
              fill: $color-tegel;
            }
          }
        }
      }
    }
  }

  a {
    text-decoration: none;
    outline: none;
    display: block;
    text-align: center;
    padding: $grid-gutter-width/6;
    color: $global-nav-color;

    .text {
      color: $color-gray;
    }

    .fa {
      transition: color 0.1s ease-in-out;
    }

    span[class*=fa] {
      &:before {
        @extend .fa;
        padding: 0 5px 0 0;
      }
    }

    &:hover, &:active, &.active, &:focus {
      text-decoration: none;

      .fa {
        /*color: $color-3;*/
        color: $color-orange;
      }
    }

    @media screen and (max-width: $screen-xs-max) {
      span[class*=fa] {
        padding: 0;
      }

      .text {
        @include sr-only();
      }
    }
  }
}
